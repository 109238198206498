import React from 'react';

export const ChatVectorIcon = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0625 0.8125H3.9375C2.69486 0.8125 1.6875 1.81986 1.6875 3.0625V8.6875C1.6875 9.93014 2.69486 10.9375 3.9375 10.9375H10.125L13.5 13.1875V10.9375H14.0625C15.3051 10.9375 16.3125 9.93014 16.3125 8.6875V3.0625C16.3125 1.81986 15.3051 0.8125 14.0625 0.8125Z"
        stroke="#5F5F8C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.625 6.4375C5.625 6.74816 5.37316 7 5.0625 7C4.75184 7 4.5 6.74816 4.5 6.4375C4.5 6.12684 4.75184 5.875 5.0625 5.875C5.37316 5.875 5.625 6.12684 5.625 6.4375Z"
        stroke="#5F5F8C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 6.4375C9 6.74816 8.74816 7 8.4375 7C8.12684 7 7.875 6.74816 7.875 6.4375C7.875 6.12684 8.12684 5.875 8.4375 5.875C8.74816 5.875 9 6.12684 9 6.4375Z"
        stroke="#5F5F8C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.375 6.4375C12.375 6.74816 12.1232 7 11.8125 7C11.5018 7 11.25 6.74816 11.25 6.4375C11.25 6.12684 11.5018 5.875 11.8125 5.875C12.1232 5.875 12.375 6.12684 12.375 6.4375Z"
        stroke="#5F5F8C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
