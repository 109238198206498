import styled from 'styled-components';
import {down} from 'styled-breakpoints';
import {Button} from '../../../../../../../ui/atoms/button';
import {Typography} from '../../../../../../../ui/atoms/typography';
import {getColorFromTheme} from '../../../../../../../ui/ui-utils';

export const ActiveWrapper = styled.div`
  position: relative;
  top: 0.6%;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #ededf2;
  border-radius: 10px 10px 0 0px;
`;

export const Wrapper = styled.div`
  width: 35%;

  @media (min-width: 768px) and (max-width: 1100px) {
    width: 45%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  margin: 80px auto 80px auto;
  padding-bottom: 80px;
`;

export const BorderWrapper = styled.div`
  border-top: 1px solid #ededf2;
  display: flex;
  width: 700px;
  max-width: 100%;
  border-radius: 10px 10px 0 2px;
  @media (max-width: 700px) {
    width: 98%;
  }
`;
export const BorderWrapper1 = styled.div`
  width: 100%;

  @media (max-width: 700px) {
    width: 98%;
  }
`;

export const BorderWrapper2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const MemberButton = styled(Button)`
  padding: 8px 16px;
`;
export const DropdownWrapper = styled.div`
  padding-left: 40px;
`;

export const HeadlineWrapper1 = styled.div`
  display: flex;
  width: 138px;
`;

export const HeadlineWrapper = styled.div`
  display: grid;
  grid-template-columns: 23% 25% 24% 10%;
  padding-left: 24px;
`;

export const HeadlineWrapperContainer1 = styled.div`
  display: block;
  padding: 16px 16px 12px 16px;
  border-top: 1px solid #ededf2;
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
  width: 200px;
`;

export const TableContainer = styled.div`
  border-right: 1px solid #ededf2;
  border-left: 1px solid #ededf2;
  border-bottom: 1px solid #ededf2;
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  ${down('md')} {
    overflow-x: auto;
  }
  @media (min-width: 990px) and (max-width: 1100px) {
    overflow-x: auto;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #f6f6f8;
  }

  &::-webkit-scrollbar-thumb {
    background: #dcdce2;
    border-radius: 100px;

    // outline: 1px solid slategrey;
  }
`;

export const ItemLoaderWrapper = styled.div`
  padding: 28px 12px 32px 22px;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ededf2;
`;

export const ItemWrapperContainer1 = styled.div``;

export const ItemWrapperContainer = styled.div``;

export const ItemWrapper1 = styled.div`
  height: 64px;
  display: flex;
  padding-right: 24px;
  padding-left: 10px;
  white-space: nowrap;
  width: 200px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 4px;
  }
  .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -70px;
  }
  &:hover .tooltip {
    // visibility: visible;
    opacity: 1;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

export const TableRow = styled.tr`
  height: 64px;
  padding-left: 24px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  max-width: 100%;
  td {
    padding: 10px;
  }
`;
export const TableHeader = styled.tr`
  padding: 16px 16px 12px 0px;
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-radius: 50px;
  width: 100px;
  border-top: 1px solid #ededf2;
  td:first-child {
    border-top-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
  }
`;

export const TagWrapper = styled.div`
  padding: 4px 7px;
  text-align: center;
`;

export const DropdownItem = styled.button`
  ${Typography};
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  display: block;
  outline: none;
  line-height: 1.7;
  padding: 6px 16px;
  transition: all ease-in-out 200ms;
  color: ${getColorFromTheme('textBody')};

  &:hover {
    background-color: ${getColorFromTheme('backgroundDark')};
    color: ${getColorFromTheme('textDark')};
  }
`;

export const PaginationUl = styled.ul`
  list-style: none;
  width: 239px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 32px 201px 0 315px;
  padding: 4px;
  border-radius: 5px;
  border: solid 1px #cecede;
  background-color: #f6f6f8;
`;

export const PaginationLi = styled.li`
  padding: 8px;
  cursor: pointer;
  margin: 0 1px;
  color: #5f5f8c;

  &:hover {
    color: #585adf;
  }

  &.active {
    width: 32px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 3px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(172, 172, 190, 0.24);
    background-color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #585adf;
    transition: background-color 0.3s ease-in;
  }
`;

export const PaginationButton = styled.button`
  border: none;
  stroke: #5f5f8c;
  strokewidth: 1.5px;
  fill: none;
  width: 100%;
  height: 100%;
  padding-top: 4px;

  &:hover {
    stroke: black;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    stroke: #5f5f8c;
  }
`;

export const JobTitleWrapper = styled.div`
  padding-top: 4px;
  margin-right: 10px;

  @media (max-width: 800px) {
    overflow: inherit;
  }
`;

export const EmailWrapper = styled.div`
  padding-top: 4px;
  margin-right: 10px;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 4px;
  }
  .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -70px;
  }
  &:hover .tooltip {
    // visibility: visible;
    opacity: 1;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  @media (max-width: 800px) {
    overflow: inherit;
  }
`;
