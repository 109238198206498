import {CSSProperties, memo} from 'react';

export const MinusSVG = memo(({style}: {style?: CSSProperties}) => (
  <svg
    width="24"
    style={style}
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#D52A2A" />
    <path
      d="M7.33203 12H16.6654"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
export const SecondaryMinusSVG = memo(({style}: {style?: CSSProperties}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none">
    <path
      d="M3.33203 8H12.6654"
      stroke="#D52A2A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

MinusSVG.displayName = 'MinusSVG';
