import {memo} from 'react';

export const JiraIcon = memo(() => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_26086_114584)">
        <path
          d="M22.9368 0H11.4102C11.4102 1.38 11.9584 2.70349 12.9342 3.6793C13.91 4.65511 15.2335 5.20331 16.6135 5.20331H18.7368V7.25344C18.7387 10.1245 21.0657 12.4516 23.9368 12.4535V0.999938C23.9368 0.447844 23.4892 0 22.9368 0Z"
          fill="#2684FF"
        />
        <path
          d="M17.2336 5.74219H5.70703C5.70881 8.61328 8.03578 10.9403 10.907 10.9422H13.0303V12.9989C13.034 15.87 15.3625 18.1955 18.2336 18.1955V6.74231C18.2336 6.19003 17.7859 5.74228 17.2336 5.74228V5.74219Z"
          fill="url(#paint0_linear_26086_114584)"
        />
        <path
          d="M11.5267 11.4844H0C0 14.3581 2.32969 16.6877 5.20331 16.6877H7.33331V18.7377C7.33519 21.6062 9.65812 23.9322 12.5267 23.9377V12.4844C12.5267 11.9321 12.0789 11.4844 11.5267 11.4844Z"
          fill="url(#paint1_linear_26086_114584)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_26086_114584"
          x1="17.987"
          y1="5.76224"
          x2="13.1135"
          y2="10.8475"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.18" stop-color="#0052CC" />
          <stop offset="1" stop-color="#2684FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_26086_114584"
          x1="12.61"
          y1="11.541"
          x2="6.97263"
          y2="17.0875"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.18" stop-color="#0052CC" />
          <stop offset="1" stop-color="#2684FF" />
        </linearGradient>
        <clipPath id="clip0_26086_114584">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});
