import React, {SVGProps} from 'react';

export const UserTickIcon = (props?: SVGProps<any>) => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25 9C10.3211 9 12 7.32107 12 5.25C12 3.17893 10.3211 1.5 8.25 1.5C6.17893 1.5 4.5 3.17893 4.5 5.25C4.5 7.32107 6.17893 9 8.25 9ZM8.25 7.875C9.69975 7.875 10.875 6.69975 10.875 5.25C10.875 3.80025 9.69975 2.625 8.25 2.625C6.80025 2.625 5.625 3.80025 5.625 5.25C5.625 6.69975 6.80025 7.875 8.25 7.875Z"
          fill="#5F5F8C"
          {...props}
        />
        <path
          d="M16.1477 7.10225C16.3674 7.32192 16.3674 7.67808 16.1477 7.89775L13.9156 10.1299C13.7013 10.3442 13.3558 10.3502 13.1342 10.1435L11.9914 9.0777C11.7642 8.86583 11.7517 8.50989 11.9636 8.28269C12.1755 8.0555 12.5314 8.04308 12.7586 8.25496L13.5042 8.95028L15.3523 7.10225C15.5719 6.88258 15.9281 6.88258 16.1477 7.10225Z"
          fill="#5F5F8C"
          {...props}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.25 13.125C14.25 14.989 11.5637 16.5 8.25 16.5C4.93629 16.5 2.25 14.989 2.25 13.125C2.25 11.261 4.93629 9.75 8.25 9.75C11.5637 9.75 14.25 11.261 14.25 13.125ZM13.125 13.125C13.125 13.47 12.8645 14.0115 11.9411 14.531C11.0529 15.0305 9.75024 15.375 8.25 15.375C6.74976 15.375 5.44705 15.0305 4.5589 14.531C3.63549 14.0115 3.375 13.47 3.375 13.125C3.375 12.78 3.63549 12.2385 4.5589 11.719C5.44705 11.2195 6.74976 10.875 8.25 10.875C9.75024 10.875 11.0529 11.2195 11.9411 11.719C12.8645 12.2385 13.125 12.78 13.125 13.125Z"
          fill="#5F5F8C"
          {...props}
        />
      </svg>
    </div>
  );
};
