import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {DropdownCustom} from './dropdown-custom';
import {ReactNode} from 'react';

interface DropdownProps {
  options: {label: string; onClick: () => void; disabled?: boolean}[];
  menu: (handleOpen: (event: any) => void, open: boolean) => ReactNode;
}

export const SimpleCustomDropdown = ({options, menu}: DropdownProps) => {
  return (
    <DropdownCustom
      collapseOnClick={true}
      menu={(handleClose: () => void) => (
        <div>
          {options.map((option) => (
            <div>
              <DropdownItem
                key={option.label}
                hoverColor="#585ADF"
                disabled={option.disabled}
                onClick={() => {
                  option.onClick();
                  handleClose();
                }}
                style={{marginBottom: '5px'}}>
                {option.label}
              </DropdownItem>
            </div>
          ))}
          <div></div>
        </div>
      )}
      customComponent={(handleOpen: (event: any) => void, open: boolean) =>
        menu(handleOpen, open)
      }
      customDropdownWrapperStyles={{
        top: '130%',
        right: 0,
        width: '190px',
      }}
    />
  );
};
