import styled from 'styled-components';
import {FlexRowSpaceBetween, FlexRowCenter} from '@ui/style/styles';

export const Wrapper = styled.div<{level: number}>`
  border: 1px solid #ededf2;
  border-radius: 10px;
  background: #ffffff;
  width: 80%;
  min-width: 783px;
  position: relative;
  margin-bottom: 20px;
  margin-left: ${({level}) => `${level * 60}px`};
`;

export const KeyResultItem = styled.div<{cascadeBg: string}>`
${({cascadeBg}) =>
  cascadeBg
    ? `background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23084B8A' strokeWidth='2.5' stroke-dasharray='14%2c 9' stroke-dashoffset='100' strokeLinecap='square'/%3e%3c/svg%3e");`
    : `background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23CECEDEFF' strokeWidth='2.5' stroke-dasharray='14%2c 9' stroke-dashoffset='100' strokeLinecap='square'/%3e%3c/svg%3e");`}

}
  padding: 0px 16px;
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 45% 20% 35%;
  margin-top: 8px;
  border-radius: 8px;
  height: 48px;
`;

export const KeyResultWrapper = styled.div`
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #ededf2;
  padding: 12px 16px 12px 60px;
`;

export const KeyResultCount = styled.div`
  position: absolute;
  top: 30%;
  left: -36px;
`;

export const GoalWrapper = styled(FlexRowSpaceBetween)`
  padding: 16px;
  min-height: 96px;
`;
export const CountWrapper = styled(FlexRowCenter)<{bg: string}>`
  width: 18px;
  background: ${({bg}) => bg};
  height: 18px;

  border-radius: 100px;
  font-weight: 500;
  cursor: pointer;
  font-size: 10px;
  color: #ffffff;
`;

export const Rule = styled.div<{
  bg: string;
  width?: string;
  height?: string;
  top?: string;
  left?: string;
}>`
  height: ${({height}) => height || '100%'};
  width: ${({width}) => width || '2px'};
  top: ${({top}) => top};
  left: ${({left}) => left || '0px'};
  background: ${({bg}) => bg};
  position: absolute;
`;
