import {memo} from 'react';

type TGreenWhitePlusIcon = {
  disabled?: boolean;
};
export const GreenWhitePlusIcon = memo(({disabled}: TGreenWhitePlusIcon) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="9" fill={disabled ? '#BFBFD4' : '#47B881'} />
    <path
      d="M9 5.5V12.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.49902 9H12.499"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

GreenWhitePlusIcon.displayName = 'GreenWhitePlusIcon';
