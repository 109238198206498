import styled from 'styled-components';
import {FlexRow} from '@ui/style/styles';
import {Body2} from '@ui/atoms/typography';
import {DropdownCustom} from '../dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {
  ArrowHeadLeftIcon,
  ArrowHeadRightIcon,
  ArrowHeadDownIcon,
} from '@ui/atoms/icons';
import {useScrollToTop} from '@utils/scrollToTop';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const PaginationUl = styled.ul`
  list-style: none;

  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 32px 0;
  padding: 4px 16px;
  border: 1px solid #ededf2;
  border-radius: 10px;
`;

export const PaginationLi = styled.li`
padding: 8px;
cursor: pointer;
margin: 0 1px;
color: #5f5f8c;
&:hover {
  color: #585adf;
}
  &.active { 
    width: 24px;
    height: 24px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 3px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(172, 172, 190, 0.24);
    background-color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #585adf;
    transition: background-color 0.3s ease-in;
    }
  };
`;
export const PaginationButton = styled.button`
  border: none;
  stroke: #5f5f8c;
  strokeWidth: 1.5px;
  fill: none;
  width: 24px;
 

  background: #F6F6F8;
  border-radius: 4px;
  height: 100%;
  padding-top: 4px;
  &:hover {
    stroke: black;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    stroke: #BFBFD4;
  }
};
`;
export interface IPagination {
  pages: Array<number>;
  maxPageNumberLimit: number;
  minPageNumberLimit: number;
  totalResults: number;
  goToPage: (value: number) => void;
  updateLimit: (value: number) => void;
  limit: number;
  showing?: number;
  page: number;
  totalPages: number;
  prevBtn: () => void;
  nextBtn: () => void;
}
export const Pagination = ({
  pages,
  maxPageNumberLimit,
  goToPage,
  prevBtn,
  totalPages,
  limit,
  showing = limit,
  nextBtn,
  totalResults,
  page,
  updateLimit,
  minPageNumberLimit,
}: IPagination) => {
  const {handleScrollToTop} = useScrollToTop();

  const limitOptions = [10, 20, 30, 40];

  const renderPageNumbers = pages.map((number: any) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit - 1) {
      return (
        <PaginationLi
          key={number}
          id={number}
          onClick={() => {
            goToPage(number);
            handleScrollToTop();
          }}
          className={page === number ? 'active' : undefined}>
          {number}
        </PaginationLi>
      );
    } else {
      return null;
    }
  });

  return (
    <PaginationWrapper>
      <PaginationUl className="bg-white">
        <Body2 kind="textBody">
          Showing <span className="font-semibold">{showing}</span> of{' '}
          <span className="font-semibold">{totalResults}</span>
        </Body2>
        <HorizontalSideRule size="24px" style={{margin: '0px 12px'}} />

        <DropdownCustom
          collapseOnClick={true}
          menu={(handleClose: () => void) => (
            <>
              {limitOptions.map((option) => (
                <DropdownItem
                  onClick={() => {
                    handleClose();
                    updateLimit(option);
                  }}>
                  {option} per page
                </DropdownItem>
              ))}
            </>
          )}
          customComponent={(
            handleOpen: (event: any) => void,
            open: boolean,
          ) => (
            <>
              <FlexRow onClick={handleOpen} style={{cursor: 'pointer'}}>
                <Body2 weight="semibold">{limit} per page</Body2>
                <ArrowHeadDownIcon
                  style={{stroke: '#242424', marginLeft: '8px'}}
                />
              </FlexRow>
            </>
          )}
          customDropdownWrapperStyles={{
            top: '-190px',

            right: 0,

            overflowY: 'auto',
          }}
        />

        <HorizontalSideRule size="24px" style={{margin: '0px 12px'}} />

        <PaginationLi>
          <PaginationButton onClick={prevBtn} disabled={page === 1}>
            <ArrowHeadLeftIcon />
          </PaginationButton>
        </PaginationLi>

        {renderPageNumbers}

        <PaginationLi>
          <PaginationButton onClick={nextBtn} disabled={page === totalPages}>
            <ArrowHeadRightIcon />
          </PaginationButton>
        </PaginationLi>
      </PaginationUl>
    </PaginationWrapper>
  );
};
