import React from 'react';

export const DashDotCircle = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.46875 9.3457C2.08875 12.3857 4.77542 14.6657 8.00209 14.6657C11.2154 14.6657 13.8954 12.3924 14.5287 9.3657"
        stroke="#BFBFD4"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5421 6.70732C13.9421 3.64065 11.2421 1.33398 8.00209 1.33398C4.78209 1.33398 2.09542 3.62066 1.46875 6.65399"
        stroke="#BFBFD4"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z"
        stroke="#BFBFD4"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
