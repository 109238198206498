import {useAnalyticsFeedbackHook} from './feedback-hook';
import {useStoreContext} from '@store/store-context';
import {FeedbackAnalytics} from '@ui/organisms/feedback/feedback-analytics';
import {FC, memo, useMemo} from 'react';

interface HomeFeedbackWidgetProps {
  activeTab: 'you' | 'team' | 'custom';
  selectedTeams?: string;
  selectedMembers?: string;
  range: {
    comparisonType: string;
    current: {
      startDate: string;
      endDate: string;
    };
    comparison: {
      startDate: string;
      endDate: string;
    };
  };
}

export const HomeFeedbackWidget: FC<HomeFeedbackWidgetProps> = memo(
  ({activeTab, range, selectedTeams, selectedMembers}) => {
    const {
      authStore: {auth},
    } = useStoreContext();

    const computeFilters = useMemo(() => {
      return {
        manager: selectedTeams
          ? selectedTeams
          : activeTab === 'team'
          ? auth.user.id
          : undefined,
        user: selectedMembers
          ? selectedMembers
          : activeTab === 'you'
          ? auth.user.id
          : undefined,
      };
    }, [activeTab, auth.user.id, selectedMembers, selectedTeams]);

    const {
      current: {summary},
      pieData,
      totalValue,
    } = useAnalyticsFeedbackHook(computeFilters, range);

    return (
      <div>
        <FeedbackAnalytics
          summary={{
            requested: summary?.requested || 0,
            given: summary?.given || 0,
          }}
          analyticsData={pieData}
          analyticsTotal={totalValue}
          impressions={{
            commend: {
              value: summary?.impressions?.commend,
              percentage: summary?.impressions?.commendPercentage,
            },
            neutral: {
              value: summary?.impressions?.neutral,
              percentage: summary?.impressions?.neutralPercentage,
            },
            critic: {
              value: summary?.impressions?.critic,
              percentage: summary?.impressions?.criticPercentage,
            },
          }}
        />
      </div>
    );
  },
);
