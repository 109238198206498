import {useFirebaseFetch} from '@hooks/query-hook';
import {Button} from '@ui/atoms/button';
import {CancelIcon} from '@ui/atoms/icons';
import {PenEditIcon} from '@ui/atoms/icons/pen-edit';
import {TickIcon} from '@ui/atoms/icons/tick';
import {TrashIcon} from '@ui/atoms/icons/trash';
import {Label} from '@ui/atoms/label';
import {RadioSm} from '@ui/atoms/radio';
import {Body1, Body2, Headline2, Headline3} from '@ui/atoms/typography';
import {SectionCard} from '@ui/layouts/section-card';
import {TextField} from '@ui/molecules/field/textfield';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {FlexRow, FlexRowCenter} from '@ui/style/styles';
import React, {FC, useMemo, useState} from 'react';
import {CustomFilterMultiSelect} from '../reporting-filter/custom-multi-select';
import {useStoreContext} from '@store/store-context';
import {userName} from '@utils/user-name';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {removeFirebaseData, updateFirebaseData} from '@utils/firebase-handler';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {useNavigate} from 'react-router';
import {Modal} from '@ui/molecules/modal';

import {BackAction} from '@ui/molecules/back-action';
import {ModalCard} from '@ui/layouts/modal-card';
import {ModalWrapper} from '@pages/1:1s/view-meetings/styles';
import {useDashboardHook} from './dashboard-hook';

interface DashboardSettingsProps {
  dashboardId: string;
  isOpen: boolean;
  handleClose: () => void;
}
const visibilityOptions = [
  {
    value: 'private',
    label: 'Owner only (private)',
  },
  {
    value: 'company-wide',
    label: 'All team members',
  },
  {
    value: 'custom',
    label: 'Custom access',
  },
];

export const DashboardSettings: FC<DashboardSettingsProps> = ({
  dashboardId,
  isOpen,
  handleClose,
}) => {
  const {data: dashboards} = useFirebaseFetch('dashboards');

  const {refetchDashboard} = useDashboardHook();
  const dashboardDetails = dashboards ? dashboards[dashboardId] : null;

  const [isEditingName, setIsEditingName] = useState(false);

  const navigate = useNavigate();
  const [nameText, setNameText] = useState('');

  const {
    usersStore: {users},
  } = useStoreContext();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const formatUsers = users.map((user) => ({
    label: userName(user),
    value: user.id,
  }));

  const [visibilitySettings, setVisibilitySettings] = useState({
    visibility: dashboardDetails?.visibility || '',
    customAccess: [] as string[],
  });

  useMemo(() => {
    if (dashboardDetails)
      setVisibilitySettings({
        visibility: dashboardDetails?.visibility || '',
        customAccess: (dashboardDetails?.customAccess as string[]) || [],
      });
  }, [dashboardDetails]);

  const updateVisibilitySettings = (
    field: keyof typeof visibilitySettings,
    value: string,
  ) => {
    setVisibilitySettings((prev) => ({...prev, [field]: value}));
  };

  const dashboardName = nameText || dashboardDetails?.name;

  const handleUpdate = async () => {
    await updateFirebaseData(`dashboards/${dashboardId}`, {
      ...dashboardDetails,
      name: dashboardName,
      ...visibilitySettings,
    });

    activateNotification({
      title: 'Success',
      content: `Dashboard updated successfully`,
      kind: 'success',
    });
    handleClose();
  };

  const handleDelete = async () => {
    await removeFirebaseData(`dashboards/${dashboardId}`);
    activateNotification({
      title: 'Success',
      content: `Dashboard deleted successfully`,
      kind: 'success',
    });

    setShowDeleteModal(false);
    handleClose();
    refetchDashboard();
    navigate('/home');
  };

  const isDisabled =
    !visibilitySettings.visibility ||
    (visibilitySettings.visibility === 'custom' &&
      !visibilitySettings.customAccess.length);

  return (
    <div>
      <DrawerModal open={isOpen} onClose={handleClose} title="">
        <div>
          <Headline3>Dashboard settings</Headline3>
          <FlexRow className="gap-2 my-6 pb-4 border-b border-b-borderLight">
            {isEditingName ? (
              <>
                <TextField
                  placeholder="Enter title (max 25 characters)"
                  margin
                  value={nameText}
                  onChange={(event) => setNameText(event.target.value)}
                  inputStyle={{width: '28vw'}}
                />
                <button
                  disabled={!nameText}
                  onClick={() => {
                    setIsEditingName(false);
                  }}>
                  <TickIcon fill={!nameText ? '#BFBFD4' : '#585ADF'} />
                </button>
                <button
                  onClick={() => {
                    setIsEditingName(false);
                  }}>
                  <CancelIcon style={{width: '24px', height: '24px'}} />
                </button>
              </>
            ) : (
              <>
                <Body1 weight="bold">{dashboardName}</Body1>

                <button
                  onClick={() => {
                    setIsEditingName(true);
                    setNameText(dashboardName);
                  }}>
                  <PenEditIcon width={'16px'} height={'16px'} fill="#585ADF" />
                </button>
              </>
            )}
          </FlexRow>
          <SectionCard
            contentStyling={{padding: '24px'}}
            CustomHeaderTitle={
              <div>
                <Body1 weight="bold" data-testid="summary-text">
                  Visibility settings
                </Body1>

                <Body2 kind="textBody">
                  Choose who can access this dashboard
                </Body2>
              </div>
            }
            variant="columnStart">
            <div>
              {visibilityOptions.map((option) => (
                <FlexRow key={option.value} className="gap-2">
                  <RadioSm
                    onChange={() => {
                      updateVisibilitySettings('visibility', option.value);
                    }}
                    checked={visibilitySettings.visibility.includes(
                      option.value,
                    )}
                  />
                  <Body1 kind={'textDark'}>{option.label}</Body1>
                </FlexRow>
              ))}

              {visibilitySettings.visibility === 'custom' && (
                <>
                  <VerticalSpacer size="24px" />

                  <Label> Select users you wish to grant access to.</Label>
                  <VerticalSpacer size="8px" />

                  <CustomFilterMultiSelect
                    options={formatUsers}
                    onChange={(data) => {
                      if (data) {
                        updateVisibilitySettings(
                          'customAccess',
                          data.map((_value: {value: string}) => _value.value),
                        );
                      }
                    }}
                    selectedOptions={formatUsers
                      .filter((user) =>
                        visibilitySettings.customAccess.includes(user.value),
                      )
                      .map((user) => ({value: user.value, label: user.label}))}
                    placeholder="Search"
                    height="52px"
                  />
                </>
              )}
            </div>
          </SectionCard>

          <VerticalSpacer size="40px" />

          <Button width="full" onClick={handleUpdate} disabled={isDisabled}>
            Save
          </Button>

          <VerticalSpacer size="40px" />

          <FlexRowCenter>
            <button onClick={() => setShowDeleteModal(true)}>
              <FlexRowCenter className="gap-1">
                <TrashIcon fill="#D52A2A" />
                <Body2 kind="red400" weight="semibold">
                  Delete Dashboard
                </Body2>
              </FlexRowCenter>
            </button>
          </FlexRowCenter>
        </div>
      </DrawerModal>
      {
        <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <ModalWrapper>
            <div className="flex justify-end mb-3">
              <BackAction
                icon={<CancelIcon />}
                title="Close"
                type="button"
                onClick={() => setShowDeleteModal(false)}
                width="md"
              />
            </div>

            <ModalCard title="">
              <div>
                <Headline2 align="center">Confirm dashboard delete</Headline2>
                <Body1 kind="textBody" align="center" className="mt-2">
                  Are you sure you want to delete “
                  <span className="text-black text-base font-medium">
                    {dashboardName}
                  </span>
                  ”?
                </Body1>
                <Body1 kind="textBody" align="center" className="mt-2">
                  This action is permanent, and while all insights will be
                  removed, they will still be available in the "Saved Reports"
                  section.
                </Body1>

                <Button width="full" onClick={handleDelete} className="mt-6">
                  Delete
                </Button>
              </div>
            </ModalCard>
          </ModalWrapper>
        </Modal>
      }
    </div>
  );
};
