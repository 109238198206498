import React from 'react';
import {BaseFilterProps} from './interface';
import {ReportingBaseFilter} from './base-filter';
import {Body2} from '@ui/atoms/typography';
import {SelectField} from '@ui/molecules/select/selectfield';

interface KPIFilterProps extends BaseFilterProps {}
export const ReportingKPIFilter = (props: KPIFilterProps) => {
  return (
    <ReportingBaseFilter {...props}>
      <>
        <div className="p-4 border-b border-b-[#ededf2]">
          <Body2 weight="bold" className="mb-2">
            KPI group tag
          </Body2>

          <SelectField
            placeholder="Select value"
            reportingStatus
            excludeSearchBox
            onChange={(data: {value: string}) => {
              props.onChangeGoalTags &&
                props.onChangeGoalTags(data?.value || '');

              props?.onChangeGoalState?.(data?.value || '');
            }}
            margin
            options={props.kpiTags ? props.kpiTags : []}
          />
        </div>
      </>
    </ReportingBaseFilter>
  );
};
