import React, {useRef, useEffect, useMemo, useState} from 'react';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import styled from 'styled-components';

dayjs.extend(isSameOrBefore);

const GraphTooltip = styled.div`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
`;

let formatter = Intl.NumberFormat('en', {notation: 'compact'});

interface DataPoint {
  endDate: string;
  prevValue?: number;
  performance: 'at_risk' | 'on_track' | 'behind';
  percentageProgress?: number;
  currentValue?: number;
}

interface MultiLineChartProps {
  data: DataPoint[];
  width?: number;
  dueDate: string;
  lastUpdated: string;
  KRtargetValue: number;
  todayDate: string;
  isKeyResult?: boolean;
  isKpi?: boolean;
  useCurrentValue?: boolean;
  performance: 'at_risk' | 'on_track' | 'behind';
  completedAt?: string;
  height?: number;
  margin: {top: number; right: number; bottom: number; left: number};
}

export const MultiLineChart: React.FC<MultiLineChartProps> = ({
  data,
  useCurrentValue,
  performance,
  KRtargetValue,
  lastUpdated,
  isKeyResult,
  dueDate,
  width,
  isKpi,
  height,
  todayDate,
  completedAt,
  margin,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const [, setStatusValue] = useState(performance);

  const sortedData = useMemo(() => {
    const validData = data.filter((d) => d.endDate);

    const uniqueData = validData.filter(
      (v, i, a) =>
        a.findIndex((t) => dayjs(t.endDate).isSame(dayjs(v.endDate))) === i,
    );

    return uniqueData.sort(
      (a, b) => dayjs(a.endDate).valueOf() - dayjs(b.endDate).valueOf(),
    );
  }, [data]);

  useEffect(() => {
    if (!svgRef.current || sortedData.length === 0) return;

    const svg = d3.select(svgRef.current);
    // / Create a overlay rect for mouse tracking

    svg.selectAll('*').remove();

    // Create tooltip
    const tooltip = d3.select(tooltipRef.current);

    const width = svgRef.current.getBoundingClientRect().width;
    const _height = height || Math.max(data.length * 30, 190); // Adjust height based on number of data points

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = _height - margin.top - margin.bottom;

    const filteredData = sortedData.filter((d) =>
      dayjs(d.endDate).isSameOrBefore(dayjs(todayDate), 'day'),
    );

    const x = d3
      .scaleTime()
      .domain([
        d3.min(filteredData, (d) => new Date(d.endDate)) || new Date(),
        d3.max(filteredData, (d) => new Date(d.endDate)) || new Date(),
      ])
      .nice() // Rounds the domain to nice month boundaries
      .range([0, chartWidth]);

    const yExtent = d3.extent(sortedData, (d) =>
      useCurrentValue
        ? Number(d.currentValue)
        : Number(d.percentageProgress?.toString().replace('%', '')),
    );

    const yMin = Math.min(0, yExtent[0] || 0);

    const yMax = Math.max(1, yExtent[1] ? yExtent[0] / 3 + yExtent[1] : 100); // Ensure a minimum range of 0 to 1

    const y = d3.scaleLinear().domain([yMin, yMax]).range([chartHeight, 0]);

    const line = d3
      .line<DataPoint>()
      .x((d) => x(new Date(d.endDate)))
      .y((d) =>
        y(
          useCurrentValue
            ? Number(d.currentValue)
            : Number(d.percentageProgress?.toString().replace('%', '')),
        ),
      )
      .defined((d) => {
        const isDefinedValue = useCurrentValue
          ? d.currentValue !== undefined && !isNaN(Number(d.currentValue))
          : d.percentageProgress !== undefined &&
            !isNaN(Number(d.percentageProgress?.toString().replace('%', '')));

        return (
          isDefinedValue &&
          dayjs(d.endDate).isSameOrBefore(dayjs(todayDate), 'day')
        );
      });

    // Function to add reference lines
    const addReferenceLine = (date: string, color: string, label: string) => {
      const xPos = x(new Date(date));

      // Add the vertical line
      g.append('line')
        .attr('x1', xPos)
        .attr('x2', xPos)
        .attr('y1', -6)
        .attr('y2', chartHeight)
        .attr('stroke', color)
        .attr('strokeWidth', 1);

      // Add the label
      g.append('text')
        .attr('x', xPos)
        .attr('y', -10) // Position above the chart
        .attr('text-anchor', 'middle')
        .attr('fill', color)
        .attr('font-size', '10px')
        .attr('font-weight', '400')
        .text(label);
    };

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    g.append('g')
      .attr('class', 'y-axis')
      .call(
        d3
          .axisLeft(y)
          .ticks(5)
          .tickFormat((d) => formatter.format(Number(d))),
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick line')
          .attr('x2', chartWidth)
          .attr('stroke-opacity', 0.1),
      )
      .call((g) =>
        g
          .selectAll('.tick text')
          .attr('fill', '#5F5F8C')
          .style('font-family', 'Graphik'),
      );
    // Get start and end dates
    const startDate =
      d3.min(filteredData, (d) => new Date(d.endDate)) || new Date();

    const endDate =
      d3.max(filteredData, (d) => new Date(d.endDate)) || new Date();

    const daysDiff = d3.timeDay.count(startDate, endDate);
    const weeksDiff = d3.timeWeek.count(startDate, endDate);
    const monthsDiff = d3.timeMonth.count(startDate, endDate);

    // Determine appropriate tick interval and count
    let xTicks;

    if (daysDiff <= 7) {
      // For 7 days or less, show daily ticks
      xTicks = d3.timeDay.range(
        d3.timeDay.floor(startDate),
        d3.timeDay.ceil(endDate),
      );
    } else if (daysDiff <= 14) {
      // For 8-14 days, show every other day
      xTicks =
        d3.timeDay
          .every(2)
          ?.range(d3.timeDay.floor(startDate), d3.timeDay.ceil(endDate)) || [];
    } else if (daysDiff <= 31) {
      // For up to a month, show every 3-4 days
      const interval = Math.max(1, Math.ceil(daysDiff / 8));
      xTicks =
        d3.timeDay
          .every(interval)
          ?.range(d3.timeDay.floor(startDate), d3.timeDay.ceil(endDate)) || [];
    } else if (monthsDiff <= 3) {
      // For 1-3 months, show weekly ticks
      const weekInterval = Math.max(1, Math.ceil(weeksDiff / 8));
      xTicks =
        d3.timeWeek
          .every(weekInterval)
          ?.range(d3.timeWeek.floor(startDate), d3.timeWeek.ceil(endDate)) ||
        [];
    } else if (monthsDiff <= 12) {
      // For 3-12 months, show monthly or bi-weekly ticks
      if (monthsDiff <= 6) {
        // For 3-6 months, show bi-weekly ticks
        xTicks =
          d3.timeWeek
            .every(2)
            ?.range(d3.timeWeek.floor(startDate), d3.timeWeek.ceil(endDate)) ||
          [];
      } else {
        // For 6-12 months, show monthly ticks
        xTicks = d3.timeMonth.range(
          d3.timeMonth.floor(startDate),
          d3.timeMonth.ceil(endDate),
        );
      }
    } else {
      // For more than a year, show every n months to maintain max 8 ticks
      const monthInterval = Math.max(1, Math.ceil(monthsDiff / 8));
      xTicks =
        d3.timeMonth
          .every(monthInterval)
          ?.range(d3.timeMonth.floor(startDate), d3.timeMonth.ceil(endDate)) ||
        [];
    }

    // If we still somehow have too many ticks, reduce them
    if (xTicks.length > 8) {
      const step = Math.ceil(xTicks.length / 8);
      xTicks = xTicks.filter((_, i) => i % step === 0);
    }

    // Format tick labels based on the range
    const tickFormat = (d: any) => {
      if (daysDiff <= 31) {
        // For ranges up to a month, show "MMM DD"
        return dayjs(d).format('MMM DD');
      } else if (monthsDiff <= 12) {
        // For ranges up to a year, show "MMM DD"
        return dayjs(d).format('MMM DD');
      } else {
        // For longer ranges, show "MMM YYYY"
        return dayjs(d).format('MMM YYYY');
      }
    };

    // X axis
    g.append('g')
      .attr('transform', `translate(0,${chartHeight})`)
      .call(
        d3.axisBottom(x).tickFormat(tickFormat).tickValues(xTicks).tickSize(5),
      )
      .call((g) => g.select('.domain').attr('stroke', '#E1E1EA'))
      .call((g) => g.selectAll('.tick line').attr('stroke', '#E1E1EA'))
      .call((g) =>
        g
          .selectAll('text')
          .attr('fill', '#5F5F8C')
          .style('font-size', '12px')
          .style('font-weight', 'normal'),
      );

    const getColor = () => {
      switch (performance) {
        case 'at_risk':
          return '#F39C9A';
        case 'on_track':
          return '#47B881';
        case 'behind':
          return '#E6821D';
        default:
          return '#BFBFD4';
      }
    };
    // Draw the line
    g.append('path')
      .datum(filteredData)
      .attr('fill', 'none')
      .attr('stroke', () => getColor())
      .attr('strokeWidth', 1)
      .attr('d', line)
      .attr('clipPath', 'url(#clipPath)');

    // Add dots for each data point
    g.selectAll('.data-point')
      .data(filteredData)
      .enter()
      .append('circle')
      .attr('class', 'data-point')
      .attr('cx', (d) => x(new Date(d.endDate)))
      .attr('cy', (d) =>
        y(
          useCurrentValue
            ? Number(d.currentValue)
            : Number(d.percentageProgress?.toString().replace('%', '')),
        ),
      )
      .attr('r', 4)
      .attr('fill', getColor())
      .attr('stroke', getColor())
      .attr('strokeWidth', 0)
      .attr('clipPath', 'url(#clipPath)');

    const cutoffDate = todayDate;

    g.append('clipPath')
      .attr('id', 'clipPath')
      .append('rect')
      .attr('width', x(new Date(cutoffDate)))
      .attr('height', chartHeight);

    if (!isKpi) {
      addReferenceLine(dayjs(dueDate).format('MM/DD/YYYY'), '#cecece', 'Due');
    }

    // Add 'Last Updated' or 'Today' reference line
    if (lastUpdated === 'completed') {
      addReferenceLine(
        dayjs(completedAt).format('MM/DD/YYYY'),
        '#585ADF',
        'Last Updated',
      );
    } else {
      addReferenceLine(
        dayjs(todayDate).format('MM/DD/YYYY'),
        '#585ADF',
        'Today',
      );
    }

    // Tooltip

    const overlay = svg
      .append('rect')
      .attr('class', 'overlay')
      .attr('width', chartWidth)
      .attr('height', chartHeight)
      .attr('opacity', 0);

    overlay
      .on('mousemove', (event: MouseEvent) => mousemove(event))
      .on('mouseout', mouseout);

    function mousemove(event: MouseEvent) {
      const [xPos] = d3.pointer(event);
      const xDate = x.invert(xPos);
      const bisect = d3.bisector((d: DataPoint) => new Date(d.endDate)).left;
      const index = bisect(data, xDate);
      const d0 = data[index - 1];
      const d1 = data[index];
      const d =
        xDate.valueOf() - new Date(d0.endDate).valueOf() >
        new Date(d1.endDate).valueOf() - xDate.valueOf()
          ? d1
          : d0;

      updateTooltip(d, event.pageX, event.pageY);
    }

    function mouseout() {
      tooltip.style('opacity', 0);
    }

    function updateTooltip(d: DataPoint, mouseX: number, mouseY: number) {
      const dateFormatter = dayjs(d.endDate).format('DD - MM - YY');
      setStatusValue(d.performance);

      let tooltipContent = `
        <div style="padding: 10px 20px; max-width: 169px; background: black; border-radius: 5px;">
      `;

      if (isKpi) {
        tooltipContent += `
          <div style="display: flex; align-items: center; gap: 8px;">
            <span style="color: white; font-size: 12px; font-weight: bold;">${
              d?.currentValue || '-'
            }</span>
        `;
      } else {
        tooltipContent += `
          <div style="color: #FFFFFF; font-weight: 400; font-size: 12px; text-align: center;">${dateFormatter}</div>
          <p style="font-weight: 500; text-align: center; margin-top: 5px; color: white;">
        `;

        if (!isKeyResult) {
          tooltipContent += getStatusSpan(d.performance);
          tooltipContent += useCurrentValue
            ? commaNumber(d.currentValue)
            : `${d.percentageProgress}%`;
        } else {
          tooltipContent += `<span style="color: white;">
            ${commaNumber(d.currentValue)} of ${commaNumber(KRtargetValue)}
          </span>`;
        }

        tooltipContent += `
          </p>
        `;
      }

      tooltipContent += `</div>`;

      const tooltip = d3.select(tooltipRef.current);
      tooltip
        .html(tooltipContent)
        .style('left', `${mouseX}px`)
        .style('top', `${mouseY}px`)
        .style('opacity', 1);
    }
  }, [
    sortedData,
    margin,
    useCurrentValue,
    performance,
    data.length,
    dueDate,
    lastUpdated,
    completedAt,
    todayDate,
    data,
    isKpi,
    KRtargetValue,
    height,
    isKeyResult,
  ]);

  return (
    <div
      data-testid="multi-line-chart"
      style={{
        width: width ? width + 'px' : '100%',
        height: height
          ? height + 'px'
          : `${Math.max(data.length * 30 + 60, 200)}px`,
      }}>
      <svg ref={svgRef} width="100%" height="100%" />
      <GraphTooltip ref={tooltipRef} />
    </div>
  );
};

function getStatusSpan(status: string): string {
  switch (status) {
    case 'on_track':
      return '<span style="color: #47B881;">On track: </span>';
    case 'at_risk':
      return '<span style="color: #F39C9A;">At risk: </span>';
    case 'behind':
      return '<span style="color: #E6821D;">Behind: </span>';
    default:
      return '<span style="color: white;">Status: </span>';
  }
}

function commaNumber(x: number | undefined): string {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
}
