import React from 'react';

export const JumpIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9745 0.911526C13.0284 0.887287 13.0784 0.951838 13.0415 0.997961L8.9046 6.16912C8.79275 6.30894 8.83829 6.5163 8.99844 6.59638L11.0025 7.59843C11.4266 7.81048 11.4142 8.41992 10.9818 8.6145L1.02501 13.0951C0.971148 13.1193 0.921107 13.0547 0.958005 13.0086L5.09494 7.83746C5.20679 7.69764 5.16125 7.49028 5.0011 7.4102L2.997 6.40816C2.5729 6.1961 2.58533 5.58667 3.01773 5.39209L12.9745 0.911526Z"
        stroke="#5F5F8C"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
