import {memo} from 'react';
import {CSSProperties} from 'styled-components';

type IconProp = {
  style?: CSSProperties;
};
export const EyeOffIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.415 9.413c-.183.197-.404.354-.65.464-.245.109-.51.168-.778.172-.269.005-.536-.044-.785-.145-.249-.1-.475-.25-.665-.44-.19-.19-.34-.416-.44-.665-.1-.25-.15-.516-.145-.785.004-.268.063-.533.173-.778.109-.246.266-.466.463-.65m5.373 5.374c-1.14.868-2.527 1.35-3.96 1.373C3.335 13.333.668 8 .668 8c.83-1.546 1.98-2.896 3.373-3.96l7.92 7.92zm-5.36-9.133c.46-.108.929-.162 1.4-.16C12.668 2.667 15.335 8 15.335 8c-.405.757-.888 1.47-1.44 2.127L6.6 2.827zM.668.667l14.667 14.666"
    />
  </svg>
));
export const SecondaryEyeOffIcon = memo(({style}: IconProp) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    style={style}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_30286_90812)">
      <path
        d="M10.59 10.59C10.384 10.8111 10.1356 10.9884 9.85961 11.1114C9.58362 11.2343 9.28568 11.3005 8.98357 11.3058C8.68146 11.3111 8.38137 11.2555 8.10121 11.1424C7.82104 11.0292 7.56654 10.8608 7.35288 10.6471C7.13923 10.4335 6.97079 10.179 6.85763 9.89881C6.74447 9.61865 6.68889 9.31856 6.69423 9.01645C6.69956 8.71434 6.76568 8.4164 6.88866 8.1404C7.01163 7.86441 7.18894 7.61601 7.41 7.41002M13.455 13.455C12.1729 14.4323 10.6118 14.9737 9 15C3.75 15 0.75 9.00002 0.75 9.00002C1.68292 7.26144 2.97685 5.74247 4.545 4.54502L13.455 13.455ZM7.425 3.18002C7.94125 3.05918 8.4698 2.99877 9 3.00002C14.25 3.00002 17.25 9.00002 17.25 9.00002C16.7947 9.85172 16.2518 10.6536 15.63 11.3925L7.425 3.18002Z"
        stroke="#5F5F8C"
        style={style}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 0.75L17.25 17.25"
        stroke="#5F5F8C"
        strokeLinecap="round"
        style={style}
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_30286_90812">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

EyeOffIcon.displayName = 'EyeOffIcon';
