import {memo} from 'react';
import {CSSProperties} from 'styled-components';

interface ICheckIcon {
  style?: CSSProperties;
}
export const CheckIcon = memo(({style}: ICheckIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    style={style}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.335 4L6 11.333 2.668 8"
    />
  </svg>
));

export const ThickCheckIcon = memo(({style}: ICheckIcon) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}>
      <path
        d="M13.3346 4L6.0013 11.3333L2.66797 8"
        stroke="#585ADF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const SubmitCheckIcon = memo(({status}: {status: boolean}) => {
  return (
    <span>
      {status ? (
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" width="16" height="16" rx="8" fill="#1A9E68" />
          <path
            d="M12.5 5L7 11L4.5 8.27273"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" width="16" height="16" rx="8" fill="#F6F6F8" />
          <path
            d="M12.5 5L7 11L4.5 8.27273"
            stroke="#BFBFD4"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </span>
  );
});

CheckIcon.displayName = 'CheckIcon';
