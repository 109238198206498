export const FlashIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.51334 9.54006H7.81834V12.5776C7.81834 13.0276 8.37334 13.2376 8.67334 12.9001L11.8683 9.27006C12.1458 8.95506 11.9208 8.46006 11.5008 8.46006H10.1958V5.42256C10.1958 4.97256 9.64084 4.76256 9.34084 5.10006L6.14584 8.73006C5.86834 9.04506 6.09334 9.54006 6.51334 9.54006Z"
        stroke="#BFBFD4"
        strokeWidth="1.2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.97656 16.5C13.1187 16.5 16.4766 13.1421 16.4766 9C16.4766 4.85786 13.1187 1.5 8.97656 1.5C4.83443 1.5 1.47656 4.85786 1.47656 9C1.47656 13.1421 4.83443 16.5 8.97656 16.5Z"
        stroke="#BFBFD4"
        strokeWidth="1.2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
