import React from 'react';

export const ChartIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3977 6.89775C11.6174 6.67808 11.6174 6.32192 11.3977 6.10225C11.1781 5.88258 10.8219 5.88258 10.6023 6.10225L8.75 7.9545L8.02275 7.22725C7.80308 7.00758 7.44692 7.00758 7.22725 7.22725L4.60225 9.85225C4.38258 10.0719 4.38258 10.4281 4.60225 10.6477C4.82192 10.8674 5.17808 10.8674 5.39775 10.6477L7.625 8.4205L8.35225 9.14775C8.57192 9.36742 8.92808 9.36742 9.14775 9.14775L11.3977 6.89775Z"
        fill="#585ADF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 8C0.5 10.8038 0.5 12.2058 1.10289 13.25C1.49784 13.9341 2.06591 14.5022 2.75 14.8971C3.79423 15.5 5.19615 15.5 8 15.5C10.8038 15.5 12.2058 15.5 13.25 14.8971C13.9341 14.5022 14.5022 13.9341 14.8971 13.25C15.5 12.2058 15.5 10.8038 15.5 8C15.5 5.19615 15.5 3.79423 14.8971 2.75C14.5022 2.06591 13.9341 1.49784 13.25 1.10289C12.2058 0.5 10.8038 0.5 8 0.5C5.19615 0.5 3.79423 0.5 2.75 1.10289C2.06591 1.49784 1.49784 2.06591 1.10289 2.75C0.5 3.79423 0.5 5.19615 0.5 8ZM13.9228 3.3125C14.1089 3.63485 14.2359 4.06323 14.3042 4.81669C14.3739 5.58562 14.375 6.57737 14.375 8C14.375 9.42263 14.3739 10.4144 14.3042 11.1833C14.2359 11.9368 14.1089 12.3651 13.9228 12.6875C13.6266 13.2006 13.2006 13.6266 12.6875 13.9228C12.3651 14.1089 11.9368 14.2359 11.1833 14.3042C10.4144 14.3739 9.42263 14.375 8 14.375C6.57737 14.375 5.58562 14.3739 4.81669 14.3042C4.06323 14.2359 3.63485 14.1089 3.3125 13.9228C2.79944 13.6266 2.37338 13.2006 2.07716 12.6875C1.89105 12.3651 1.76406 11.9368 1.69577 11.1833C1.62608 10.4144 1.625 9.42263 1.625 8C1.625 6.57737 1.62608 5.58562 1.69577 4.81669C1.76406 4.06323 1.89105 3.63485 2.07716 3.3125C2.37338 2.79944 2.79944 2.37338 3.3125 2.07716C3.63485 1.89105 4.06323 1.76406 4.81669 1.69577C5.58562 1.62608 6.57737 1.625 8 1.625C9.42263 1.625 10.4144 1.62608 11.1833 1.69577C11.9368 1.76406 12.3651 1.89105 12.6875 2.07716C13.2006 2.37338 13.6266 2.79944 13.9228 3.3125Z"
        fill="#585ADF"
      />
      <path
        d="M2.07716 12.6875C2.37338 13.2006 2.79944 13.6266 3.3125 13.9228C3.63485 14.1089 4.06323 14.2359 4.81669 14.3042C5.58562 14.3739 6.57737 14.375 8 14.375C9.42263 14.375 10.4144 14.3739 11.1833 14.3042C11.9368 14.2359 12.3651 14.1089 12.6875 13.9228C13.2006 13.6266 13.6266 13.2006 13.9228 12.6875C14.1089 12.3651 14.2359 11.9368 14.3042 11.1833C14.3739 10.4144 14.375 9.42263 14.375 8C14.375 6.57737 14.3739 5.58562 14.3042 4.81669C14.2359 4.06323 14.1089 3.63485 13.9228 3.3125C13.6266 2.79944 13.2006 2.37338 12.6875 2.07716M2.07716 12.6875L1.10289 13.25M2.07716 12.6875C1.89105 12.3651 1.76406 11.9368 1.69577 11.1833C1.62608 10.4144 1.625 9.42263 1.625 8C1.625 6.57737 1.62608 5.58562 1.69577 4.81669C1.76406 4.06323 1.89105 3.63485 2.07716 3.3125C2.37338 2.79944 2.79944 2.37338 3.3125 2.07716C3.63485 1.89105 4.06323 1.76406 4.81669 1.69577C5.58562 1.62608 6.57737 1.625 8 1.625C9.42263 1.625 10.4144 1.62608 11.1833 1.69577C11.9368 1.76406 12.3651 1.89105 12.6875 2.07716M1.10289 13.25C0.5 12.2058 0.5 10.8038 0.5 8C0.5 5.19615 0.5 3.79423 1.10289 2.75C1.49784 2.06591 2.06591 1.49784 2.75 1.10289C3.79423 0.5 5.19615 0.5 8 0.5C10.8038 0.5 12.2058 0.5 13.25 1.10289C13.9341 1.49784 14.5022 2.06591 14.8971 2.75C15.5 3.79423 15.5 5.19615 15.5 8C15.5 10.8038 15.5 12.2058 14.8971 13.25C14.5022 13.9341 13.9341 14.5022 13.25 14.8971C12.2058 15.5 10.8038 15.5 8 15.5C5.19615 15.5 3.79423 15.5 2.75 14.8971C2.06591 14.5022 1.49784 13.9341 1.10289 13.25ZM12.6875 2.07716L13.2479 1.10648M11.3977 6.10225C11.6174 6.32192 11.6174 6.67808 11.3977 6.89775L9.14775 9.14775C8.92808 9.36742 8.57192 9.36742 8.35225 9.14775L7.625 8.4205L5.39775 10.6477C5.17808 10.8674 4.82192 10.8674 4.60225 10.6477C4.38258 10.4281 4.38258 10.0719 4.60225 9.85225L7.22725 7.22725C7.44692 7.00758 7.80308 7.00758 8.02275 7.22725L8.75 7.9545L10.6023 6.10225C10.8219 5.88258 11.1781 5.88258 11.3977 6.10225Z"
        stroke="#585ADF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
