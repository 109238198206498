import {memo} from 'react';
import styled from 'styled-components';
import {InsightCard} from '@pages/dashboard/reporting/dashboard/insight-card';
import {useInsightActionItemHook} from '@pages/dashboard/reporting/insights/action-items/action-items-insight-hook';
import {useStoreContext} from '@store/store-context';

const GridItem = styled.div<{w: number}>`
  width: 100%;
  height: 100%;
  min-height: 180px;
  grid-column: span 1 / span 1;

  @media (min-width: 768px) {
    grid-column: span ${(props) => Math.min(2, props.w / 3)} / span
      ${(props) => Math.min(2, props.w / 3)};
  }

  @media (min-width: 1200px) {
    grid-column: span ${(props) => Math.min(12, props.w)} / span
      ${(props) => Math.min(12, props.w)};
  }
`;

export const Card = styled.div<{bg: string}>`
  min-width: 48%;
  .card-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
  }

  border-radius: 8px;
  border: 1px solid #ededf2;
  width: 100%;

  .compare {
    border-top: 1px solid #ededf2;
    display: flex;
    padding: 4px 12px;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

export const Value = styled.div<{bg: string}>`
  border: 1px solid ${({bg}) => bg};
  background-color: #f6f6fe;
  padding: 4px 12px;
  border-radius: 8px;
`;

interface HomeActionItemsWidgetProps {
  activeTab: 'you' | 'team' | 'custom';
  members?: string[];
  range: {
    comparisonType: string;
    current: {
      startDate: string;
      endDate: string;
    };
    comparison: {
      startDate: string;
      endDate: string;
    };
  };
}
export const HomeActionItemsWidget = memo(
  ({activeTab, range, members}: HomeActionItemsWidgetProps) => {
    const {
      authStore: {auth},
      usersStore: {users},
    } = useStoreContext();

    const team = users.filter((user) => user.reviewer.id === auth.user.id);

    const {summary} = useInsightActionItemHook({
      date: range.current,
      comparisonDate: range.comparison,
      members: members
        ? members
        : activeTab === 'you'
        ? [auth.user.id]
        : activeTab === 'team'
        ? team.map((user) => user.id)
        : [],
      sections: [],
    });

    const actionItemDetails = [
      {
        name: `Action items`,
        showPercentProgress: true,
        value: `${summary.total.current ?? ''}`,
        percentProgress: summary.total.change,
      },
      {
        name: `Completed Action items`,
        showPercentProgress: true,
        value: `${summary.completed.current ?? ''}`,
        percentProgress: summary.completed.change,
      },
      {
        name: `Completion rate`,
        showPercentProgress: true,
        value: `${summary.completionRate.current ?? ''}%`,
        percentProgress: summary.completionRate.change,
      },
    ];

    return (
      <div
        className={`grid grid-flow-row gap-5 w-full h-auto grid-cols-2 sm:grid-cols-1 md:grid-cols-2 laptop:grid-cols-12`}>
        {actionItemDetails.map((actionItem) => (
          <GridItem w={4}>
            <InsightCard
              key={actionItem.name}
              {...actionItem}
              showRangeType
              comparisonText={range.comparisonType}
              disableEdit
              config={{
                comparison: {
                  type: '',
                  value: '',
                },
                type: '',
                range: {
                  type: '',
                  value: '',
                },
              }}
            />
          </GridItem>
        ))}
      </div>
    );
  },
);
