import {CancelIcon} from '@ui/atoms/icons';
import {Body2} from '@ui/atoms/typography';
import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {FC, useMemo, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';

export const pulseOptions = [
  {
    value: 'elated',
    label: 'Elated',
    color: '#2485E5',
  },
  {
    label: 'Happy',
    value: 'happy',
    color: '#47B881',
  },
  {
    label: 'Meh',
    value: 'meh!',
    color: '#E87F16',
  },
  {
    label: 'Sad',
    value: 'sad',
    color: '#FFBC00',
  },
  {
    label: 'Drained',
    value: 'drained',
    color: '#F39C9A',
  },
];

interface PulseProps {
  onChangePulse?: (data: string[]) => void;
  pulse?: string[];
}
export const PulseToggles: FC<PulseProps> = ({onChangePulse, pulse = []}) => {
  const [selectedPulse, setSelectedPulse] = useState<string[]>(pulse);

  const delayOnChange = useDebouncedCallback(
    (data) => onChangePulse?.(data),
    1500,
  );

  const computeSelectedOptions = useMemo(() => {
    return pulseOptions.filter((pulse) => selectedPulse.includes(pulse.value));
  }, [selectedPulse]);

  const handleChange = (value: string[]) => {
    setSelectedPulse(value);

    delayOnChange(value);
  };

  const removeOption = (option: string) => {
    handleChange(selectedPulse.filter((_option) => option !== _option));
  };

  return (
    <div>
      <FlexRowSpaceBetween className="mb-3">
        <Body2 weight="bold">Pulse</Body2>
      </FlexRowSpaceBetween>

      <MultiOptionsSelectField
        options={pulseOptions}
        performance
        showSelections={false}
        groupDisplayLength={5}
        excludeSearchBox
        reportingStyling
        searchPlaceholder="Select value"
        excludeCheckBox
        margin
        height="44px"
        showSearchIcon
        defaultValue={selectedPulse.map((pulse) => ({value: pulse}))}
        noOptionsMessage={`No pulse not found`}
        placeholder={`Search for pulse`}
        onChange={(data: any) => {
          handleChange(data.map((pulse: {value: string}) => pulse.value));
        }}
      />
      <div className="flex flex-row  flex-wrap">
        {computeSelectedOptions.map((option) => (
          <div
            key={option.value}
            style={{border: `1px solid ${option.color}`}}
            className={`py-1 px-2 
              w-fit
             bg-[#F6F6F8]  flex flex-row items-center justify-between rounded-[6px] mt-4 mr-3 `}>
            <Body2>{option.label}</Body2>

            <div
              className="cursor-pointer"
              onClick={() => {
                removeOption(option.value);
              }}>
              <CancelIcon
                style={{
                  width: '12px',
                  height: '12px',
                  strokeWidth: 1.5,
                  marginLeft: '15px',
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const CheckinsAdditionalFilterOptions = [] as {
  value: string;
  label: string;
}[];

export const CheckinsGenderOptions = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
];
