import {Fragment, memo, ReactNode, SyntheticEvent} from 'react';
import styled, {CSSProperties} from 'styled-components';
import {AnimateSharedLayout} from 'framer-motion';
import {DropdownItem} from '../../../pages/dashboard/you/you-page.styles';
import {HeaderNav} from '../../atoms/header-nav';
import {FiChevronDown} from 'react-icons/fi';
import {DropdownCustom} from '../dropdown-custom';
import {ColorPurple300} from '@ui/atoms/color-icons';
import {HorizontalSpacer} from '../../atoms/spacer';
import {NavLink} from 'react-router-dom';
import {getColorFromTheme} from '../../ui-utils';
import {HeaderSecondaryNav} from '../../atoms/header-nav/header-nav';

interface NavigationOption {
  href?: string;
  title: string | ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
  addBottomBorder?: boolean;
  children?: NavigationOption[];
  active: boolean;
  id: string;
  onClick: (e: SyntheticEvent<HTMLAnchorElement>) => void;
}

const Wrapper = styled.div`
  width: auto;
`;

const TitleWrapper = styled.span`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
  @media (min-width: 1200px) {
    display: block;
  }
`;
const NavWrapper = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 16px;
  position: relative;
`;

const SecondaryNavWrapper = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 1px ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('backgroundDark')};
  border-radius: 5px;
  height: 46px;
  padding: 4px 6px;
  position: relative;
`;

const MobileNavBackground = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  color: #585adf;
  font-weight: 600;
  font-size: 14px;
  background-color: ${getColorFromTheme('purple100')};
  z-index: 1;
  border-radius: 8px;
  width: 100%;
`;

export interface NavigationProps {
  navigations: NavigationOption[];
  icons?: boolean;
  kind?: string;
  variant?: string;
  type?: 'button' | 'link';
  activeStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  as?: string;
  style?: CSSProperties;
  navStyle?: CSSProperties;
}

export const Navigation = memo(function ({navigations}: NavigationProps) {
  return (
    <Wrapper>
      <AnimateSharedLayout>
        <NavWrapper>
          {navigations.map((item: NavigationOption) => (
            <Fragment key={item.id}>
              <HeaderNav
                href={item.href}
                disabled={item.disabled}
                active={item.active}
                childNavigations={item?.children as any}
                onClick={item.onClick}>
                {item.icon && <>{item.icon}</>}

                <TitleWrapper>
                  {item.icon && <HorizontalSpacer size="7px" />}
                  {item.title}
                </TitleWrapper>
              </HeaderNav>
              {item.addBottomBorder && (
                <div className="w-full h-[1px] bg-borderDark " />
              )}
            </Fragment>
          ))}
        </NavWrapper>
      </AnimateSharedLayout>
    </Wrapper>
  );
});

export const SecondaryNavigation = memo(function ({
  navigations,
  icons,
  style,
  variant,
  type,
  kind,
  activeStyle,
  bodyStyle,
  navStyle,
}: NavigationProps) {
  return (
    <Wrapper style={style}>
      <AnimateSharedLayout>
        <SecondaryNavWrapper style={navStyle}>
          {navigations.map((item) => (
            <HeaderSecondaryNav
              href={item.href}
              type={type}
              active={item.active}
              activeStyle={activeStyle}
              bodyStyle={bodyStyle}
              key={item.id}
              kind={kind}
              icons={icons}
              variant={variant}
              onClick={item.onClick}>
              {item.title}
            </HeaderSecondaryNav>
          ))}
        </SecondaryNavWrapper>
      </AnimateSharedLayout>
    </Wrapper>
  );
});
Navigation.displayName = 'Navigation';

export const MobileNavigation = memo(function ({
  style,
  navStyle,
  navigations,
}: NavigationProps) {
  const getNavigations = navigations.flatMap((nav) => {
    if (nav.children && nav.children?.length > 0) {
      return nav.children;
    }
    return nav;
  });

  return (
    <div style={{position: 'relative'}}>
      <>
        <DropdownCustom
          menu={(handleClose: () => void) => (
            <>
              {getNavigations.map((item) => (
                <div key={item.id}>
                  <NavLink to={item.href ?? '#'} onClick={item.onClick}>
                    <DropdownItem
                      disabled={item.disabled}
                      onClick={() => handleClose()}>
                      {item.title}
                    </DropdownItem>
                  </NavLink>
                </div>
              ))}
            </>
          )}
          collapseOnClick={true}
          customDropdownWrapperStyles={{
            right: '-12%',
            top: '120%',
            width: '170px',
          }}
          customComponent={(handleOpen: (event: any) => void) => (
            <>
              <Wrapper style={style}>
                <AnimateSharedLayout>
                  <NavWrapper style={navStyle}>
                    <MobileNavBackground onClick={handleOpen}>
                      <ColorPurple300 width="8px" height="8px" margin={6} />
                      <HorizontalSpacer size="4px" />

                      {getNavigations.map((item) => (
                        <span key={item.id}>
                          {item.active ? item.title : ''}
                        </span>
                      ))}
                      <HorizontalSpacer size="6px" />
                      <FiChevronDown strokeWidth={'3px'} />
                    </MobileNavBackground>
                  </NavWrapper>
                </AnimateSharedLayout>
              </Wrapper>
            </>
          )}
        />
      </>
    </div>
  );
});
