import React from 'react';

export const HelpGuideIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill="#CECEDE" />
      <path
        d="M5.59863 5.67029C5.7388 5.23849 5.99565 4.85386 6.34078 4.55894C6.68592 4.26401 7.10589 4.07027 7.55426 3.99915C8.00264 3.92802 8.46195 3.98228 8.88141 4.15592C9.30087 4.32956 9.66414 4.61582 9.93106 4.98305C10.198 5.35027 10.3581 5.78416 10.3938 6.23674C10.4295 6.68931 10.3394 7.14294 10.1333 7.54748C9.92728 7.95201 9.61338 8.29168 9.22633 8.52893C8.83928 8.76619 8.39415 8.89178 7.94017 8.89183V9.50721M7.97094 11.9688V12.0303H7.9094V11.9688H7.97094Z"
        stroke="#FAFAFA"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
