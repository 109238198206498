import {memo} from 'react';

export const UserIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    stroke="rgba(88, 90, 223, 1)"
    strokeWidth={'1px'}
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.335 14v-1.333c0-.708-.281-1.386-.781-1.886S11.375 10 10.668 10H5.335c-.708 0-1.386.281-1.886.781s-.781 1.178-.781 1.886V14M7.999 7.333c1.472 0 2.666-1.194 2.666-2.666C10.665 3.194 9.471 2 8 2 6.526 2 5.332 3.194 5.332 4.667c0 1.472 1.194 2.666 2.667 2.666z"
    />
  </svg>
));
UserIcon.displayName = 'UserIcon';
