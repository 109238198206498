import styled from 'styled-components';
import {down, up} from 'styled-breakpoints';
import {getColorFromTheme} from '../../ui/ui-utils';
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    margin-top: 24px;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
  }
`;
export const AdditionalWrapper = styled.div<{display?: boolean}>`
  display: ${(props: any) => (props.display === true ? 'block' : 'none')};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(206, 206, 222, 1)' strokeWidth='2' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
  margin: 8px 0;
  width: 100%;
  height: 56px;
  padding: 2px 2px;
`;
export const AdditionalErrorWrapper = styled.div<{display?: boolean}>`
  display: ${(props: any) => (props.display === true ? 'block' : 'none')};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(213, 42, 42, 1)' strokeWidth='2' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
  margin: 2px 0;
  width: 100%;
  height: 52px;
  padding: 2px 2px;
`;
export const Wrapper = styled.div`
  width: 75vw;
  margin: 0 auto;
  max-width: 928px;

  ${down('sm')} {
    width: 92vw;
    padding: 20px 0;
  }

  ${up('sm')} {
    width: 95vw;
    padding: 40px 0;
  }

  ${up('lg')} {
    width: 70vw;
    padding: 80px 0;
  }

  ${up('xl')} {
    width: 928px;
    padding: 80px 0px;
  }
`;

export const TableContainer = styled.div`
  min-width: 900px;
`;

export const HistoryTableContainer = styled.div`
  overflow-x: auto;
`;

export const HistoryTable = styled.div<{type?: string}>`
  display: grid;
  grid-template-columns: ${(props: any) =>
    props.type === 'admin' ? '25% 30% 15% 20%' : '40% 13% 10%'};
  grid-gap: 1%;
  border-top: 1px solid #ededf2;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
  background: #fafafa;
  height: 52px;
  > span {
    display: flex;
    align-items: center;
  }

  ${down('sm')} {
    grid-template-columns: ${(props: any) =>
      props.type === 'admin' ? '30% 18% 15% 15%' : '40% 13% 10%'};
  }
  ${up('sm')} {
    grid-template-columns: ${(props: any) =>
      props.type === 'admin' ? '25% 22% 15% 30%' : '40% 13% 10%'};
  }

  ${up('lg')} {
    grid-template-columns: ${(props: any) =>
      props.type === 'admin' ? '25% 20% 15% 30%' : '40% 13% 10%'};
  }

  ${up('xl')} {
    grid-template-columns: ${(props: any) =>
      props.type === 'admin' ? '25% 27% 10% 20%' : '40% 13% 10%'};
  }
`;

export const ImportErrorTable = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  grid-gap: 1%;

  font-size: 14px;
  font-weight: 500;
  color: ${getColorFromTheme('textBody')};
  border-radius: 10px 10px 0px 0px;
  padding: 16px;
  background: #fafafa;
  height: 52px;
  > span {
    display: flex;
    align-items: center;
  }
`;

export const HistoryTableItems = styled.div<{type?: string}>`
  display: grid;
  grid-template-columns: ${(props: any) =>
    props.type === 'admin' ? '25% 20% 10% 30% 10%' : '40% 13% 30% 10%'};
  grid-gap: 1%;
  font-size: 14px;
  color: ${getColorFromTheme('textDark')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  padding: 16px;
  ${down('sm')} {
    grid-template-columns: ${(props: any) =>
      props.type === 'admin' ? '30% 20% 10% 25% 10%' : '40% 13% 30% 10%'};
  }

  ${up('sm')} {
    grid-template-columns: ${(props: any) =>
      props.type === 'admin' ? '25% 23% 15% 20% 15%' : '40% 13% 30% 15%'};
  }

  ${up('lg')} {
    grid-template-columns: ${(props: any) =>
      props.type === 'admin' ? '25% 20% 15% 25% 10%' : '40% 13% 30% 15%'};
  }
  ${up('xl')} {
    grid-template-columns: ${(props: any) =>
      props.type === 'admin' ? '25% 27% 10% 20% 10%' : '40% 13% 30% 10%'};
  }
  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
  }
`;
export const ImportErrorTableItems = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  grid-gap: 1%;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  font-size: 14px;
  color: ${getColorFromTheme('textDark')};

  padding: 16px;
  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
  }
`;
export const UploadWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  padding: 40px;
  @media (max-width: 500px) {
    padding: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  border: 1px solid #cecede;
  border-radius: 8px;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  justify-content: space-between;
  text-align: center;
  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;
export const ImportErrorWrapper = styled.div`
  overflow-y: scroll;
  height: 240px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const ImportErrorSpan = styled.span`
  overflow-x: scroll;
  display: flex;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
