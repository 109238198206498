import React from 'react';

export const AtRiskIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6654 8H11.9987L9.9987 14L5.9987 2L3.9987 8H1.33203"
        stroke="#D52A2A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
