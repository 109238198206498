export const HorizontalDots = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.9987 9.16683C8.64303 9.16683 9.16536 8.6445 9.16536 8.00016C9.16536 7.35583 8.64303 6.8335 7.9987 6.8335C7.35437 6.8335 6.83203 7.35583 6.83203 8.00016C6.83203 8.6445 7.35437 9.16683 7.9987 9.16683Z"
        fill="#5E5E73"
        stroke="#5E5E73"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9987 4.49984C8.64303 4.49984 9.16536 3.9775 9.16536 3.33317C9.16536 2.68884 8.64303 2.1665 7.9987 2.1665C7.35437 2.1665 6.83203 2.68884 6.83203 3.33317C6.83203 3.9775 7.35437 4.49984 7.9987 4.49984Z"
        fill="#5E5E73"
        stroke="#5E5E73"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9987 13.8333C8.64303 13.8333 9.16536 13.311 9.16536 12.6667C9.16536 12.0223 8.64303 11.5 7.9987 11.5C7.35437 11.5 6.83203 12.0223 6.83203 12.6667C6.83203 13.311 7.35437 13.8333 7.9987 13.8333Z"
        fill="#5E5E73"
        stroke="#5E5E73"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
