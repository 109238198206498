import {IMembersFormatFilter} from '@hooks';
import {useMemo, useCallback} from 'react';
import {FeedbackController} from '@pages/feedback/feedback-controller';
import {FeedbackRequestImpl} from '@pages/feedback/feedback-requests';
import {useState} from 'react';
import {usePaginationHook} from '@hooks/pagination-hook';
import {useFetchHook} from '@hooks/query-hook';
import {IOptions} from '@pages/feedback/components/values-themes-filter';
import dayjs from 'dayjs';

type DateRange = {
  current: {
    startDate: string;
    endDate: string;
  };
  comparison: {
    startDate: string;
    endDate: string;
  };
};

export const useAnalyticsFeedbackHook = (
  computedFilters: any,
  dateRange: DateRange,
) => {
  const [userFilter, setUserFilter] = useState({
    filterBy: {
      label: 'People',
      value: 'people',
    },
    selectedTeam: {
      value: '',
      label: '',
    },
    members: [] as Array<IMembersFormatFilter>,
  });

  const {
    pages,
    handlePrevBtn,
    nextPageBtn,
    updateLimit,
    paginationPages,
    updateTotalPages,
    goToPage,
    updateTotalResults,
  } = usePaginationHook();

  const [valueThemeFilter, setValueThemeFilter] = useState<IOptions>({
    filterBy: '',
    theme: '',
    value: '',
  });

  const request = useMemo(() => new FeedbackRequestImpl(), []);
  const controller = useMemo(() => new FeedbackController(request), [request]);

  const filters = useMemo(
    () => ({
      ...computedFilters,
      startDate: dayjs(dateRange.current.startDate).format('MM-DD-YYYY'),
      endDate: dayjs(dateRange.current.endDate).format('MM-DD-YYYY'),
      impression: valueThemeFilter.theme,
    }),
    [computedFilters, dateRange, valueThemeFilter.theme],
  );

  const comparisonFilters = useMemo(
    () => ({
      ...computedFilters,
      startDate: dayjs(dateRange.comparison.startDate).format('MM-DD-YYYY'),
      endDate: dayjs(dateRange.comparison.endDate).format('MM-DD-YYYY'),
      impression: valueThemeFilter.theme,
    }),
    [computedFilters, dateRange.comparison, valueThemeFilter.theme],
  );

  const emptySummary = {
    averageImpression: '',
    impressions: {
      commend: 0,
      commendPercentage: 0,
      neutral: 0,
      neutralPercentage: 0,
      critic: 0,
    },
    requested: 0,
    totalFeedback: 0,
    values: [],
  };

  const getFeedBackReporting = useCallback(
    async (filters: any) => {
      const response = await controller.getFeedbackReporting(filters);
      updateTotalPages(Math.round(response?.users?.length / pages.limit) || 0);
      updateTotalResults(response?.users?.length || 0);
      return response;
    },
    [controller, updateTotalPages, updateTotalResults, pages.limit],
  );

  const {
    data: currentData = {summary: emptySummary, users: []},
    isLoading: isLoadingCurrent,
  } = useFetchHook(['reporting-feedback-current', filters], () =>
    getFeedBackReporting(filters),
  );

  const {
    data: comparisonData = {summary: emptySummary, users: []},
    isLoading: isLoadingComparison,
  } = useFetchHook(
    ['reporting-feedback-comparison', comparisonFilters],
    () => getFeedBackReporting(comparisonFilters),
    {
      enabled: false,
    },
  );

  const formatPagination = useMemo(
    () => ({
      starts: pages.page * pages.limit - pages.limit,
      ends: pages.page * pages.limit,
    }),
    [pages.limit, pages.page],
  );

  const sortUser = useCallback((data: Array<{feedback: {given: number}}>) => {
    return data?.sort((a, b) => b?.feedback.given - a?.feedback?.given);
  }, []);

  const currentReportingData = useMemo(
    () => ({
      ...currentData,
      users: sortUser(currentData?.users)?.slice(
        formatPagination.starts,
        formatPagination.ends,
      ),
    }),
    [formatPagination, currentData, sortUser],
  );

  const comparisonReportingData = useMemo(
    () => ({
      ...comparisonData,
      users: sortUser(comparisonData?.users)?.slice(
        formatPagination.starts,
        formatPagination.ends,
      ),
    }),
    [formatPagination, comparisonData, sortUser],
  );

  const {impressions, requested, totalFeedback, values} =
    currentReportingData?.summary || emptySummary;

  const pieData = useMemo(
    () =>
      totalFeedback === 0
        ? [{name: '0', value: 100, color: '#EDEDF2'}]
        : [
            {name: 'commend', value: impressions.commend, color: '#47B881'},
            {name: 'Neutral', value: impressions.neutral, color: '#CECEDE'},
            {name: 'Critic', value: impressions.critic, color: '#FFD5AD'},
          ],
    [totalFeedback, impressions],
  );

  return {
    userFilter,
    setUserFilter,
    isLoading: isLoadingCurrent || isLoadingComparison,
    current: {
      summary: currentReportingData?.summary,
      users: currentReportingData?.users || [],
    },
    comparison: {
      summary: comparisonReportingData?.summary,
      users: comparisonReportingData?.users || [],
    },
    pieData,
    totalValue: totalFeedback,
    feedbackSummary: [
      {title: 'Feedback requested', value: requested},
      {title: 'Feedback received', value: totalFeedback},
    ],
    totalResults: currentData?.users?.length || 0,
    impressions,
    values,
    controller,
    filters,
    nextPageBtn,
    updateLimit,
    handlePrevBtn,
    goToPage,
    paginationPages,
    pages,
    valueThemeFilter,
    setValueThemeFilter,
  };
};
