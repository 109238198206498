import {memo} from 'react';
export const CsvIcon = memo(() => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.58594 0.832031C3.89558 0.832031 3.33594 1.39168 3.33594 2.08203V17.9154C3.33594 18.6057 3.89558 19.1654 4.58594 19.1654H15.4193C16.1096 19.1654 16.6693 18.6057 16.6693 17.9154V5.83203L11.6693 0.832031H4.58594Z"
      fill="#20A464"
    />
    <path
      d="M4.58594 19.1654C3.89558 19.1654 3.33594 18.6057 3.33594 17.9154V17.707C3.33594 18.3974 3.89558 18.957 4.58594 18.957H15.4193C16.1096 18.957 16.6693 18.3974 16.6693 17.707V17.9154C16.6693 18.6057 16.1096 19.1654 15.4193 19.1654H4.58594Z"
      fill="#149456"
    />
    <path
      d="M4.58594 0.832031C3.89558 0.832031 3.33594 1.39168 3.33594 2.08203V2.29036C3.33594 1.60001 3.89558 1.04036 4.58594 1.04036H11.8776L11.6693 0.832031H4.58594Z"
      fill="#38AE74"
    />
    <path
      d="M16.6681 10.1055V5.83464L15.9389 5.10547H12.4225C12.1441 5.10547 12.0047 5.44208 12.2015 5.63894L16.6681 10.1055Z"
      fill="url(#paint0_linear_16535_71570)"
    />
    <path
      d="M11.6641 0.832031L16.6641 5.83203H12.9141C12.2237 5.83203 11.6641 5.27239 11.6641 4.58203V0.832031Z"
      fill="#8ED1B1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3307 9.79297V15.8346H6.66406V9.79297H13.3307ZM9.58073 10.6263H7.4974V11.668H9.58073V10.6263ZM10.4141 11.668V10.6263H12.4974V11.668H10.4141ZM9.58073 12.293H7.4974V13.3346H9.58073V12.293ZM10.4141 13.3346V12.293H12.4974V13.3346H10.4141ZM9.58073 13.9596H7.4974V15.0013H9.58073V13.9596ZM10.4141 15.0013V13.9596H12.4974V15.0013H10.4141Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_16535_71570"
        x1="14.3887"
        y1="10.1055"
        x2="14.3887"
        y2="5.0013"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#20A464" />
        <stop offset="1" stop-color="#207E55" />
      </linearGradient>
    </defs>
  </svg>
));

CsvIcon.displayName = 'CsvIcon';
