import {motion} from 'framer-motion';
import styled from 'styled-components';
import {down} from 'styled-breakpoints';
import {Button} from '../../../../../../../ui/atoms/button';
import {getColorFromTheme} from '../../../../../../../ui/ui-utils';
import {ifProp} from 'styled-tools';

export const SearchFilterWrapper = styled.div`
  display: inline-block;
  width: 100%;
  padding: 16px 24px;
`;

export const FirstWrapper = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
`;

export const TableContainer = styled.div``;

export const TableWrapper = styled.div``;
export const SecondWrapperHeader = styled.div`
  padding: 16px 16px;

  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SummaryWrapper = styled.div`
  padding: 16px 16px 10px;
`;

export const SecondWrapperHeader2 = styled.div`
  padding: 10px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

export const FirstWrapperChild = styled(motion.div)`
  display: block;
  padding: 16px 24px;
  width: 100%;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
`;

export const SearchWrapper = styled.div`
  width: 100%;
`;

export const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 44%) minmax(auto, 55%);
  grid-gap: 12px;
  width: 100%;
  margin-top: 16px;
`;

export const FilterBoxWrapper = styled.span`
  width: 100%;
`;

export const DateWrapper = styled.span`
  width: 100%;
`;

export const FirstWrapperHeaderSpanLeft = styled.span`
  float: left;
  padding-top: 8px;
`;

export const ThirdWrapperHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const ThirdWrapperHeaderMiddle = styled.div`
  text-align: right;
`;

export const ThirdWrapperHeaderRight = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: right;
  color: ${getColorFromTheme('textBody')};

  >span: not(: first-child) {
    margin-left: 16px;
  }
`;

export const FirstWrapperHeaderSpanRight = styled.span`
  float: right;
`;

export const CustomFontWeight = styled.span`
  font-weight: 500;
  color: #1e1e2f;
  font-size: 14px;
`;

export const FlexSummary = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const EmailButton = styled(Button)`
  line-height: 1;
  padding: 16px 0;
  font-size: 14px;
  font-weight: 500;

  &:disabled {
  }
`;

export const SecondWrapperChildHeader = styled.div`
  display: grid;
  grid-template-columns: 40% 18% 20% 12%;
  grid-gap: 1%;
  font-size: 14px;
  color: ${getColorFromTheme('textBody')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px 0 0 0;
  padding: 16px;
  background: #fafafa;
  height: 52px;
  > span {
    display: flex;
    align-items: center;
  }
`;
export const SecondMainChildHeader = styled.div<{includeProgress?: boolean}>`
  display: grid;

  width: 100%;
  min-width: 400px;
  grid-template-columns: ${ifProp(
    'includeProgress',
    '55% 17% 16% 15%',
    '74% 20% 15%',
  )};
  grid-gap: 1%;

  font-size: 14px;

  font-weight: 500;
  padding: 16px;
  background: #fafafa;
  height: 52px;
  > span {
    display: flex;
    align-items: center;
  }
`;
export const SecondFlexHeader = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0px 10px 0px 0px;
  border-right: 1px solid #ededf2;
  background: #fafafa;
  height: 52px;
  > span {
    display: flex;
    align-items: center;
  }
`;

export const SecondFlexItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  > span {
    display: flex;
    align-items: center;
  }
`;

export const SecondWrapperChildHeader2 = styled.div`
  display: grid;
  grid-template-columns: 18.8% 16.7% 14.7% 8.5% 9.9% 9.9% 9.9% 9.2%;
  font-size: 14px;
  color: ${getColorFromTheme('textBody')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px 0 0 0;
  padding: 16px 0 16px 16px;
  background: #fafafa;
  height: 52px;
  grid-gap: 6px;
`;

export const SecondWrapperChildGoals = styled.div<{
  removeBorder?: boolean;
  includeProgress?: boolean;
  groupBy?: string;
}>`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: ${({groupBy, includeProgress}) =>
    groupBy
      ? '55% 36% 15%'
      : includeProgress
      ? '55% 20% 15% 15%'
      : '74% 20% 15%'};

  grid-gap: 1%;
  width: 100%;
  min-width: 580px;
  font-size: 14px;
  height: 120px;
  color: ${getColorFromTheme('textDark')};
  border-bottom: ${({removeBorder}) =>
    removeBorder ? undefined : '1px solid #ededf2'};
  padding: 16px;
  @media (max-width: 1400px) and (min-width: 1000px) {
    min-width: 510px;
  }
  p {
    overflow: hidden;

    text-overflow: ellipsis;
  }
  .goalProgress .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -50px;
    margin-left: -7%;
  }
  .goalProgress:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .goalProgress .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
    line-height: 75px;
    padding-right: 8px;
  }

  .tooltip_container {
    display: inline-flex;
    padding-right: 24px;
  }

  .tooltip_container .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -20px;
  }
  .tooltip_container:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .tooltip_container .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

export const SecondWrapperChildTreeGoals = styled.div`
  display: block;
  background: ${getColorFromTheme('backgroundLight')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
`;

export const ZoomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${getColorFromTheme('borderLight')};
  height: 32px;
  width: 106px;
  border-radius: 6px;
  padding: 4px 8px 4px 8px;

  svg {
    stroke: ${getColorFromTheme('textDark')};
    position: relative;
    top: 2.7px;
  }
`;

export const TreeViewGoalsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${getColorFromTheme('borderLight')};
  overflow: auto;
  padding: 24px;
  width: 1040px;

  &:last-child {
    border: none;
  }
`;

export const TreeViewGoalsCard = styled.div<{height?: boolean}>`
  display: block;
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 8px;
  padding: 10px;
  background: #ffffff;
  width: 328px;
  height: ${(props: any) => (props.height ? '140px' : '100%')};
  // box-shadow: 0px 9px 38px rgba(0, 0, 0, 0.05),
  //   0px 6.53341px 11.4559px rgba(0, 0, 0, 0.0325794);
`;

export const TreeViewGoalsCardFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TreeViewGoalsCardButton = styled.button`
  position: relative;
  left: 22px;
  top: 18px;
  background: ${getColorFromTheme('purple300')};
  border-radius: 20px;
  color: #ffffff;
  width: 18px;
  height: 18px;
  font-size: 10px;
  padding-top: 2px;

  > svg {
    stroke: ${getColorFromTheme('white')};
  }
`;

export const TreeViewKeyResultsWrapper = styled.div`
  height: 105px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23CECEDEFF' strokeWidth='2.5' stroke-dasharray='14%2c 9' stroke-dashoffset='100' strokeLinecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  margin: 12px 0 0 0;
  padding: 10px 0 10px 12px;
`;

export const TreeViewKeyResultsButton = styled.button<{isOpen?: boolean}>`
  display: flex;
  align-items: center;
  color: ${(props: any) => (props.isOpen ? '#585adf' : '#1e1e2f')};
  font-size: 14px;
  weight: 500;

  svg {
    fill: ${(props: any) => (props.isOpen ? '#585adf' : '#1e1e2f')};
    stroke: ${(props: any) => (props.isOpen ? '#585adf' : '#1e1e2f')};
    transform: ${(props: any) =>
      props.isOpen ? 'rotate(0deg)' : 'rotate(-90deg)'};
  }
`;

export const GoalTypeTag = styled.div`
  height: 26px;
  padding: 4px 8px;
  background: #f6f6f8;
  border-radius: 8px;
  font-size: 12px;
  color: #5f5f8c;
  font-weight: 500;
  line-height: 18px;
  width: fit-content;
`;

export const SecondWrapperChildKeyResults = styled.div`
  display: grid;
  grid-template-columns: 50% 25% 25%;
  font-size: 14px;
  color: ${getColorFromTheme('textDark')};
  padding: 16px;
  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
  }
  .keyResultName .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -75px;
  }
  .krResults {
    margin-left: 70px;
    @media (max-width: 1300px) {
      margin-left: 10px;
    }
  }
  .keyResultName:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .keyResultName .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;
export const SecondWrapperChildKeyResultsGraph = styled.div<{variant?: string}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  color: ${getColorFromTheme('textDark')};
  padding: ${({variant}) => (variant === 'member' ? '16px 0px' : '16px')};

  @media (max-width: 1400px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ${down('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }
  .endwrapper {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${down('sm')} {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .keyResultName {
      width: 80%;
      ${down('sm')} {
        width: 100%;
      }
    }
    .keyResultName .tooltip {
      visibility: hidden;
      width: auto;
      background-color: rgba(0, 0, 0, 0.7);
      font-size: 12px;
      color: #fff;
      text-align: center;
      padding: 12px;
      border-radius: 6px;
      position: absolute;
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 1;
      margin-top: -75px;
    }
    .krResults {
      margin-left: 40px;
      @media (max-width: 1300px) {
        margin-left: 10px;
      }
    }
    .keyResultName:hover .tooltip {
      visibility: visible;
      opacity: 1;
    }

    .keyResultName .tooltip::after {
      content: ' ';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
`;

export const GraphTooltip = styled.div`
  z-index: 1000;
  position: relative;
  .keyResultName::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  .keyResult100::after {
    content: ' ';
    position: absolute;
    top: -25%;
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

export const SecondWrapperChildKeyResults2 = styled.div`
  display: grid;
  grid-template-columns: 40% 40% 20%;
  font-size: 14px;
  color: ${getColorFromTheme('textDark')};
  padding: 16px;
  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
  }

  .keyResultName .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -75px;
  }
  .keyResultName:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .keyResultName .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

export const SecondWrapperChildNestedKeyResults = styled.div`
  display: grid;
  grid-template-columns: 40% 38% 22%;
  font-size: 14px;
  color: ${getColorFromTheme('textDark')};
  background: ${getColorFromTheme('backgroundLight')};
  border-left: 1px solid ${getColorFromTheme('borderDark')};
  margin: 0px 0px 0px 25px;
  padding: 22px 14px 22px 0px;
  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
  }
`;

export const SecondWrapperChildPeople = styled.div`
  display: grid;
  grid-template-columns: 18.8% 16.7% 14.7% 8.5% 9.9% 9.9% 9.9% 9.2%;
  font-size: 14px;
  color: ${getColorFromTheme('textDark')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  padding: 16px 0 16px 16px;
  height: 64px;
  grid-gap: 6px;
  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
    padding-right: 16px;
  }
  .ellipsis2 {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
    display: block;
    padding-right: 16px;
  }

  .tooltip_container2 {
    display: inline-block;
  }

  .tooltip_container2 .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -70px;
  }
  .tooltip_container2:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .tooltip_container2 .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

export const Divider = styled(motion.div)`
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  padding-bottom: 15px;
  margin: 0 32px 0 10px;
`;

export const NestedDivider = styled(motion.div)`
  border-top: 1px solid ${getColorFromTheme('borderLight')};
`;

export const Content = styled(motion.div)`
  padding-top: 15px;
`;
export const ResultContent = styled(motion.div)`
  padding-top: 5px;
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
`;
export const PaginationUl = styled.ul`
  list-style: none;
  width: 239px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;
  gap: 8px;

  padding: 4px;
  border-radius: 5px;
  border: solid 1px #cecede;
  background-color: #f6f6f8;
  @media (max-width: 500px) {
    margin: 32px 20px 0 60px;
  }
`;

export const PaginationLi = styled.li`
padding: 8px;
cursor: pointer;
margin: 0 1px;
color: #5f5f8c;

&:hover {
  color: #585adf;
}

  &.active { 
    width: 32px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 3px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(172, 172, 190, 0.24);
    background-color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #585adf;
    transition: background-color 0.3s ease-in;
    }
  };
`;

export const PaginationButton = styled.button`
  border: none;
  stroke: #5f5f8c;
  strokeWidth: 1.5px;
  fill: none;
  width: 100%;
  height: 100%;
  padding-top: 4px;

  &:hover {
    stroke: black;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    stroke: #5f5f8c;
  }
};
`;

export const ItemLoaderWrapper = styled.div`
  display: block;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  padding: 16px;
`;

export const QuestionIconWrapper = styled.span<{tooltip?: boolean}>`
  .tooltip {
    visibility: hidden;
    width: 170px;
    background-color: #fafafa;
    font-size: 14px;
    color: #5f5f8c;
    text-align: center;
    padding: 16px;
    border: 1px solid #e4e5fb;
    border-radius: 5px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 3;
    left: -110px;
    margin-top: -110px;
  }
  &:hover .tooltip {
    visibility: ${(props: any) => (props.tooltip === true ? 'visible' : '')};
    opacity: 1;
  }

  .tooltip::before {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 69.5%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: #e4e5fb transparent transparent transparent;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 69.7%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #fafafa transparent transparent transparent;
  }
`;
