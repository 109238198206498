import styled from 'styled-components';
import {Typography} from '../../../../../../../../ui/atoms/typography';
import {getColorFromTheme} from '../../../../../../../../ui/ui-utils';
import {FlexRowCenter} from '@ui/style/styles';

export const DropdownWrapper = styled.div`
  padding-left: 40px;
  display: flex;
  align-items: center;
`;

export const HeadlineWrapper1 = styled.div`
  display: flex;
  width: 138px;
`;

export const HeadlineWrapper = styled.div`
  display: grid;
  grid-template-columns: 23% 25% 24% 13% 10%;
  padding-left: 24px;
`;

export const HeadlineWrapperContainer1 = styled.div`
  display: block;
  padding: 16px 16px 12px 16px;
  border-top: 1px solid #ededf2;
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
  width: 200px;
`;

export const HeadlineWrapperContainer = styled.div`
  display: block;
  border-top: 1px solid #ededf2;
  padding: 16px 16px 12px 0px;
  width: 100%;
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
`;

export const TableContainer = styled.div`
  border-right: 1px solid #ededf2;
  border-top: 1px solid #ededf2;
  border-left: 1px solid #ededf2;
  border-bottom: 1px solid #ededf2;
  border-radius: 0px 0px 10px 10px;
`;

export const ItemLoaderWrapper = styled.div`
  padding: 28px 12px 32px 22px;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ededf2;
`;

export const ItemWrapperContainer1 = styled.div``;

export const ItemWrapperContainer = styled.div``;

export const ItemWrapper1 = styled.div`
  height: 64px;
  display: flex;
  padding-right: 24px;
  padding-left: 10px;
  white-space: nowrap;
  width: 200px;

  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 4px;
  }
  .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -70px;
  }
  &:hover .tooltip {
    // visibility: visible;
    opacity: 1;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

export const ItemWrapper = styled.div`
  height: 64px;
  display: grid;
  grid-template-columns: 22% 25% 23% 10% 20%;
  padding-left: 24px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SecondRowWrapper = styled.div`
  width: 100%;

  min-width: 800px;
`;

export const TableWrapper = styled.div`
  display: flex;

  overflow: auto;
`;

export const TagWrapper = styled.div`
  padding: 4px 7px;
  text-align: center;
`;

export const DropdownItem = styled.button`
  ${Typography};
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  display: block;
  outline: none;
  line-height: 1.7;
  padding: 6px 16px;
  transition: all ease-in-out 200ms;
  color: ${getColorFromTheme('textBody')};

  &:hover {
    background-color: ${getColorFromTheme('backgroundDark')};
    border-radius: 10px;
    color: ${getColorFromTheme('textDark')};
  }
`;

export const PaginationUl = styled.ul`
  list-style: none;
  width: 239px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 32px 201px 0 315px;
  padding: 4px;
  border-radius: 5px;
  border: solid 1px #cecede;
  background-color: #f6f6f8;
`;

export const PaginationLi = styled.li`
padding: 8px;
cursor: pointer;
margin: 0 1px;
color: #5f5f8c;

&:hover {
  color: #585adf;
}

  &.active { 
    width: 32px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 3px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(172, 172, 190, 0.24);
    background-color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #585adf;
    transition: background-color 0.3s ease-in;
    }
  };
`;

export const PaginationButton = styled.button`
  border: none;
  stroke: #5f5f8c;
  strokeWidth: 1.5px;
  fill: none;
  width: 100%;
  height: 100%;
  padding-top: 4px;

  &:hover {
    stroke: black;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    stroke: #5f5f8c;
  }
};
`;

export const JobTitleWrapper = styled.div`
  padding-top: 4px;
  margin-right: 10px;

  @media (max-width: 800px) {
    overflow: inherit;
  }
`;
export const HelperInfoDiv = styled.div`
  // border: 1px dashed ${getColorFromTheme('borderLight')};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(237, 237, 242, 1)' strokeWidth='2' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");

  padding: 24px;
  border-radius: 12px;
  div.info {
    display: flex;
    span.price {
      font-weight: 500;
      color: ${getColorFromTheme('textDark')};
    }
  }
`;
export const EmailWrapper = styled.div`
  padding-top: 4px;
  margin-right: 10px;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 4px;
  }
  .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -70px;
  }
  &:hover .tooltip {
    // visibility: visible;
    opacity: 1;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  @media (max-width: 800px) {
    overflow: inherit;
  }
`;

export const ChangeWrapper = styled(FlexRowCenter)`
  @media (max-width: 500px) {
    margin-left: 10px;
    margin-bottom: 16px;
  }
`;
export const ShowingWrapper = styled.div`
  display: flex;
  @media (max-width: 500px) {
    margin-left: 10px;
    margin-bottom: 16px;
  }
`;
