import styled from 'styled-components';
import {down} from 'styled-breakpoints';
import {Button} from '../../../ui/atoms/button';
import {Typography} from '../../../ui/atoms/typography';
import {getColorFromTheme} from '../../../ui/ui-utils';

export const QuestionIconWrapper = styled.button<{tooltip?: boolean}>`
  margin-left: 8px;
  outline: none;
  svg {
    width: 14px;
    height: 14px;
    stroke: ${getColorFromTheme('purple300')};
    margin-bottom: -2px;
  }
  @media (max-width: 500px) {
    display: none;
  }
  .tooltip {
    visibility: hidden;
    width: 399px;
    background-color: #fafafa;
    font-size: 14px;
    color: #5f5f8c;
    text-align: center;
    padding: 16px 16px 16px 26px;
    border: 1px solid #e4e5fb;
    border-radius: 5px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    left: 0;
    margin-top: -100px;
  }
  &:hover .tooltip {
    visibility: ${(props: any) => (props.tooltip === true ? 'visible' : '')};
    opacity: 1;
  }

  .tooltip::before {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 11.5%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: #e4e5fb transparent transparent transparent;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 11.7%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #fafafa transparent transparent transparent;
  }
`;

export const Wrapper = styled.div`
  padding-bottom: 256px;
`;

export const ModalWrapper = styled.div`
  background: #585adf;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  padding-right: 24px;
  @media (max-width: 578px) {
    display: block;
  }
`;

export const CustomButton = styled(Button)`
  padding: 8px 35px 8px 16px;
  svg {
    stroke: ${getColorFromTheme('textDark')};
    position: absolute;
    top: 12px;
    margin: 0 0 0 8px;
  }
`;

export const MenuButton = styled(Button)`
  display: flex;
  align-items: center;
  text-transform: capitalize;

  ${down('md')} {
    padding: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
`;
export const MenuButtonPrimary = styled(Button)`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  text-transform: capitalize;

  ${down('md')} {
    padding: 8px 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
`;

export const CustomCancelIcon = styled.button`
  border: none;
  svg {
    stroke: ${getColorFromTheme('white')};
    width: 24px;
    height: 24px;
    position: relative;
    top: 8px;
    margin-left: 30px;
  }
`;

export const PopUpTextWrapper = styled.div`
  padding-top: 21px;
  width: 100%;
  position: relative;
  right: 12px;
  @media (max-width: 578px) {
    right: 0;
    padding-left: 16px;
  }
`;

export const PopUpButtonWrapper = styled.div`
  display: inline-block;
  width: 50%;
  @media (max-width: 578px) {
    right: 0;
    padding-left: 16px;
    margin-bottom: 16px;
    width: auto;
  }
`;
export const PopUpButtonInnerWrapper = styled.div`
  padding: 21px 0 0 0;
  float: right;
  white-space: nowrap;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const PaginationUl = styled.ul`
  list-style: none;
  width: 239px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 32px 0;
  padding: 4px;
  border-radius: 5px;
  border: solid 1px #cecede;
  background-color: #f6f6f8;
`;

export const PaginationLi = styled.li`
padding: 8px;
cursor: pointer;
margin: 0 1px;
color: #5f5f8c;
&:hover {
  color: #585adf;
}
  &.active { 
    width: 32px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 3px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(172, 172, 190, 0.24);
    background-color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #585adf;
    transition: background-color 0.3s ease-in;
    }
  };
`;

export const PaginationButton = styled.button`
  border: none;
  stroke: #5f5f8c;
  strokeWidth: 1.5px;
  fill: none;
  width: 100%;
  height: 100%;
  padding-top: 4px;
  &:hover {
    stroke: black;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    stroke: #5f5f8c;
  }
};
`;
export const DropdownWrapper = styled.div``;

export const DropdownItem = styled.button<{
  background?: string;
  hoverColor?: string;
  color?: string;
}>`
  ${Typography};
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  border: none;
  background: ${({background}) => background || 'none'};
  display: block;
  outline: none;
  line-height: 1.7;
  padding: 6px 16px;
  border-radius: 8px;
  white-space: nowrap;
  transition: all ease-in-out 200ms;
  color: ${({color}) => color || getColorFromTheme('textBody')};

  &:hover {
    background-color: ${getColorFromTheme('backgroundDark')};

    font-weight: 500;
    color: ${({hoverColor}) => hoverColor || getColorFromTheme('textDark')};
  }
  &:disabled {
    cursor: not-allowed;
    color: ${getColorFromTheme('textMuted')};
  }
`;

export const ReviewerBox = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  .title-div {
    padding: 10px;
  }
  .content-div {
    border-top: 1px solid ${getColorFromTheme('borderLight')};

    padding: 16px 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      text-align: center;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      justify-content: center;
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
`;
