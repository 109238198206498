import {CustomColorIcon} from '@ui/atoms/color-icons';
import {RoundedPieChart} from '@ui/atoms/pie-chart/pie';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body1, Body2, Headline3, Microcopy} from '@ui/atoms/typography';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {FC, memo} from 'react';
import styled from 'styled-components';

const ResponsiveFlexRow = styled(FlexRow)`
  width: 100%;
  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
`;

const ResponsiveFlexSpaceBetween = styled(FlexRowSpaceBetween)`
  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const FeedbackCard = styled.div`
  padding: 8px 16px;
  gap: 16px;

  width: 100%;
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ededf2;
  border-radius: 10px;
  .number {
    background: #ededf2;
    border-radius: 8px;
    padding: 6px;
  }
`;

type Impression = {value: number; percentage: number};

interface FeedbackAnalyticsProps {
  impressions: {
    commend: Impression;
    neutral: Impression;
    critic: Impression;
  };
  analyticsData: {name: string; value: number; color: string}[];

  analyticsTotal: number;
  summary: {
    requested: number;
    given: number;
  };
}

export const FeedbackAnalytics: FC<FeedbackAnalyticsProps> = memo(
  ({impressions, analyticsData, analyticsTotal, summary}) => {
    return (
      <ResponsiveFlexSpaceBetween className="gap-6">
        <div className="bg-white w-full shadow-[0_3px_24px_0_rgba(208,208,221,0.16)] py-6 px-4 border h-full rounded-[10px] border-borderLight  min-h-[320px]">
          <Body1 weight="bold">Received feedback sentiment</Body1>
          <ResponsiveFlexRow className="w-full justify-between items-center h-full mt-8">
            <RoundedPieChart
              data={analyticsData}
              totalValue={analyticsTotal}
              chartStyle={{width: 170, height: 170}}
              pieStyle={{width: '300px'}}
            />
            <div className="w-[50%]">
              <Summary
                title={'Commend'}
                value={impressions.commend.value}
                percentageValue={impressions.commend.percentage}
                showBorder
                color="#1A9E68"
              />
              <Summary
                title={'Neutral'}
                value={impressions.neutral.value}
                percentageValue={impressions.neutral.percentage}
                showBorder
                color="#CECEDE"
                textColor="black"
              />
              <Summary
                title={'Critic'}
                value={impressions.critic.value}
                percentageValue={impressions.critic.percentage}
                color="#E87F16"
                textColor="black"
              />
            </div>
          </ResponsiveFlexRow>
        </div>

        <div className="bg-white w-full  shadow-[0_3px_24px_0_rgba(208,208,221,0.16)] py-6 px-4 border h-full rounded-[10px] border-borderLight  min-h-[320px]">
          <Body1 weight="bold">Feedback Exchange</Body1>
          <VerticalSpacer size="40px" />
          <FeedbackCard>
            <Body1 weight={'bold'}>Feedback requested</Body1>
            <div className="number">
              <Headline3>{summary.requested}</Headline3>
            </div>
          </FeedbackCard>
          <FeedbackCard>
            <Body1 weight={'bold'}>Feedback given</Body1>
            <div className="number">
              <Headline3>{summary.given}</Headline3>
            </div>
          </FeedbackCard>
        </div>
      </ResponsiveFlexSpaceBetween>
    );
  },
);

const Summary = memo(
  ({
    title,
    value,
    percentageValue,
    showBorder,
    color,
    textColor,
  }: {
    title: string;
    value: number;
    percentageValue: number;
    showBorder?: boolean;
    color: string;
    textColor?: string;
  }) => {
    return (
      <FlexRowSpaceBetween
        style={{
          borderBottom: showBorder ? '1px solid #ededf2' : '',
          padding: '16px 0',
        }}>
        <FlexRow>
          <CustomColorIcon
            width="10px"
            height="10px"
            margin={5}
            color={color}
          />{' '}
          <Body2 style={{color: textColor || color}}>{title}</Body2>
        </FlexRow>
        <Body2 weight="semibold">
          {value}
          <span style={{marginLeft: '3px'}}>
            <Microcopy kind="textBody" weight="default">
              ({percentageValue}%)
            </Microcopy>
          </span>
        </Body2>
      </FlexRowSpaceBetween>
    );
  },
);
