import React from 'react';

export const ConfigIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 14V8"
        stroke="#5E5E73"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.33333V2"
        stroke="#5E5E73"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.332 13.9974V10.6641"
        stroke="#5E5E73"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.332 8V2"
        stroke="#5E5E73"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 5.33594H10"
        stroke="#5E5E73"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.332 10.6641H15.332"
        stroke="#5E5E73"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
