import {memo} from 'react';

export const UploadCircleIcon = memo(() => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12329_55517)">
      <path
        d="M11.8125 7.3125L9 4.5L6.1875 7.3125"
        stroke="#5F5F8C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.9375V4.5"
        stroke="#5F5F8C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 17.4375C13.6599 17.4375 17.4375 13.6599 17.4375 9C17.4375 4.3401 13.6599 0.5625 9 0.5625C4.3401 0.5625 0.5625 4.3401 0.5625 9C0.5625 13.6599 4.3401 17.4375 9 17.4375Z"
        stroke="#5F5F8C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12329_55517">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

UploadCircleIcon.displayName = 'UploadCircleIcon';
