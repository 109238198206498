import {memo} from 'react';

export const ArrowDownIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 3.333v9.334M12.665 8L8 12.667 3.332 8"
    />
  </svg>
));

ArrowDownIcon.displayName = 'ArrowDownIcon';

export const ArrowDownSecondary = memo(() => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.16927 13.0438C6.48938 13.6104 5.50181 13.6104 4.82193 13.0438L1.17955 10.0085C0.310989 9.28471 0.822813 7.87103 1.95343 7.87103C2.06877 7.87103 2.16226 7.77753 2.16226 7.6622L2.16226 2.37103C2.16226 1.35851 2.98307 0.537694 3.9956 0.537694L7.9956 0.537694C9.00812 0.537694 9.82893 1.3585 9.82893 2.37103L9.82893 7.66219C9.82893 7.77753 9.92243 7.87103 10.0378 7.87103C11.1684 7.87103 11.6802 9.28471 10.8116 10.0085L7.16927 13.0438ZM5.46211 12.2756C5.77115 12.5331 6.22005 12.5331 6.52908 12.2756L10.1715 9.24029C10.3215 9.11525 10.2331 8.87103 10.0378 8.87103C9.37014 8.87103 8.82893 8.32981 8.82893 7.66219L8.82893 2.37103C8.82893 1.91079 8.45583 1.53769 7.9956 1.53769L3.9956 1.53769C3.53536 1.53769 3.16226 1.91079 3.16226 2.37103L3.16226 7.6622C3.16226 8.32982 2.62105 8.87103 1.95343 8.87103C1.75811 8.87103 1.66969 9.11525 1.81974 9.24029L5.46211 12.2756Z"
      fill="#585ADF"
    />
  </svg>
));
