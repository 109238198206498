import styled from 'styled-components';
import {CancelIcon} from '../icons';
import {getColorFromTheme} from '@ui/ui-utils';
import {updateTooltipStatus} from '@utils/firebase-request';

import {ReactNode} from 'react';
import {Body2} from '../typography';

interface THint {
  children: ReactNode;
  showCancelIcon?: boolean;
  handleCancel?: () => void;
  id?: string;
  isDismissed?: boolean;
}

const Wrapper = styled.div`
  background: ${getColorFromTheme('backgroundDark')};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const CancelButtton = styled.button`
  border: 0;
  background: none;
  padding: 0;
`;

export const HintBlock = ({children, showCancelIcon, handleCancel}: THint) => {
  return (
    <>
      <Wrapper>
        {children}
        {showCancelIcon && (
          <CancelButtton onClick={handleCancel}>
            {' '}
            <CancelIcon />{' '}
          </CancelButtton>
        )}
      </Wrapper>
    </>
  );
};

const InformationWrapper = styled.div`
  background: ${getColorFromTheme('backgroundDark')};
  border-radius: 8px;
  padding: 8px 16px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoCancelButtton = styled.button`
  border: 0;
  background: none;
  padding: 0;
  svg {
    margin-top: 2px;
    strokewidth: 1.5px;
  }
`;
export const InformationBlock = ({children, id, isDismissed}: THint) => {
  if (isDismissed) {
    return null;
  }

  return (
    <>
      <InformationWrapper>
        <div />
        <Body2 kind="textBody"> {children}</Body2>
        <InfoCancelButtton
          type="button"
          onClick={() => {
            if (id) {
              updateTooltipStatus(id, {isDismissed: true});
            }
          }}>
          {' '}
          <CancelIcon />{' '}
        </InfoCancelButtton>
      </InformationWrapper>
    </>
  );
};
