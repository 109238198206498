import React, {SVGProps} from 'react';

export const TrashIcon = (props?: SVGProps<any>) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.59867 2.63763C2.55462 2.64345 2.51268 2.65483 2.47345 2.67098C1.94913 2.71194 1.48908 2.75266 1.1578 2.78339C0.988339 2.7991 0.852475 2.81221 0.758825 2.8214L0.651062 2.8321L0.613752 2.83587L0.613032 2.83595C0.338334 2.86415 0.138512 3.1097 0.166716 3.3844C0.194921 3.6591 0.440473 3.85892 0.715171 3.83072L0.714681 3.82593C0.715173 3.83072 0.715171 3.83072 0.715171 3.83072L0.751079 3.82708L0.856524 3.81662C0.948567 3.80758 1.08264 3.79465 1.25014 3.77911C1.58523 3.74804 2.05371 3.7066 2.58693 3.66516C3.65702 3.58202 4.97435 3.5 5.99743 3.5C7.02052 3.5 8.33785 3.58202 9.40794 3.66516C9.94116 3.7066 10.4096 3.74804 10.7447 3.77911C10.9122 3.79465 11.0463 3.80758 11.1383 3.81662L11.2438 3.82708L11.2792 3.83067C11.5539 3.85888 11.7999 3.6591 11.8282 3.3844C11.8564 3.1097 11.6565 2.86415 11.3818 2.83595L11.3438 2.8321L11.236 2.8214C11.1424 2.81221 11.0065 2.7991 10.8371 2.78339C10.5058 2.75266 10.0457 2.71193 9.52138 2.67097C9.48697 2.65682 9.45044 2.64632 9.41217 2.64C9.08234 2.58558 8.80469 2.36327 8.67946 2.05333L8.61917 1.9041C8.27631 1.05553 7.4527 0.5 6.53748 0.5H5.63935C4.73625 0.5 3.92355 1.04817 3.58524 1.8855C3.4212 2.29149 3.05147 2.57786 2.61736 2.63517L2.59867 2.63763ZM5.63935 1.5C5.14389 1.5 4.69802 1.80074 4.51241 2.26012C4.47212 2.35984 4.42502 2.45561 4.37174 2.54693C4.93792 2.51858 5.49902 2.5 5.99743 2.5C6.55057 2.5 7.1809 2.52289 7.80972 2.5566C7.78913 2.51461 7.76996 2.4717 7.75228 2.42794L7.69199 2.27872C7.50184 1.8081 7.04506 1.5 6.53748 1.5H5.63935Z"
        fill="#BFBFD4"
        {...props}
      />
      <path
        d="M10.4956 5.04331C10.5195 4.76821 10.3159 4.5258 10.0407 4.50188C9.76565 4.47796 9.52324 4.68158 9.49931 4.95669L8.96251 11.1299C8.89511 11.9051 8.24621 12.5 7.46815 12.5H4.26019C3.45711 12.5 2.79649 11.8675 2.76161 11.0652L2.49696 4.97828C2.48497 4.7024 2.2516 4.48848 1.97572 4.50047C1.69983 4.51247 1.48591 4.74584 1.49791 5.02172L1.76255 11.1086C1.82069 12.4458 2.92171 13.5 4.26019 13.5H7.46815C8.76492 13.5 9.84641 12.5085 9.95875 11.2166L10.4956 5.04331Z"
        fill="#BFBFD4"
        {...props}
      />
      <path
        d="M3.99743 10.5C3.72129 10.5 3.49743 10.7239 3.49743 11C3.49743 11.2761 3.72129 11.5 3.99743 11.5H7.99743C8.27358 11.5 8.49743 11.2761 8.49743 11C8.49743 10.7239 8.27358 10.5 7.99743 10.5H3.99743Z"
        fill="#BFBFD4"
        {...props}
      />
      <path
        d="M3.49743 9C3.49743 8.72386 3.72129 8.5 3.99743 8.5H7.99743C8.27358 8.5 8.49743 8.72386 8.49743 9C8.49743 9.27614 8.27358 9.5 7.99743 9.5H3.99743C3.72129 9.5 3.49743 9.27614 3.49743 9Z"
        fill="#BFBFD4"
        {...props}
      />
      <path
        d="M3.99743 6.5C3.72129 6.5 3.49743 6.72386 3.49743 7C3.49743 7.27614 3.72129 7.5 3.99743 7.5H7.99743C8.27358 7.5 8.49743 7.27614 8.49743 7C8.49743 6.72386 8.27358 6.5 7.99743 6.5H3.99743Z"
        fill="#BFBFD4"
        {...props}
      />
    </svg>
  );
};
