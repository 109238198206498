import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {Button} from '@ui/atoms/button';
import {Checkbox} from '@ui/atoms/checkbox';
import {CancelIcon, ChevronDownIcon} from '@ui/atoms/icons';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {Body2} from '@ui/atoms/typography';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {getGroupTypeAndGroup} from '@utils/get-group-grouptype';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {capitalize} from '@utils';
import {FC, useMemo, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {CustomFilterMultiSelect} from '../reporting-filter/custom-multi-select';
import {useStoreContext} from '@store/store-context';
import {SecondaryMinusSVG} from '@ui/atoms/svg/minus';

export const statusToggles = [
  {
    label: 'On track',
    value: 'on_track',
    color: '#47B881',
  },
  {
    label: 'Behind',
    value: 'behind',
    color: '#E6821D',
  },
  {
    label: 'At risk',
    value: 'at_risk',
    color: '#D52A2A',
  },
  {
    label: 'Not updated',
    value: 'no_status',
    color: '#E4E5FB',
  },
];

interface StatusProps {
  onChangeStatus?: (data: string[]) => void;
  showObjectiveStatus: boolean;
  onHide: () => void;
  status?: string[];
}

export const GoalStatusToggles: FC<StatusProps> = ({
  onChangeStatus,
  showObjectiveStatus,
  onHide,
  status = [],
}) => {
  const [selectedStatus, setSelectedStatus] = useState<string[]>(status);

  const delayOnChange = useDebouncedCallback(
    (data) => onChangeStatus?.(data),
    1500,
  );

  const handleChange = (value: string) => {
    const updatedValue = selectedStatus.includes(value)
      ? selectedStatus.filter((_status) => _status !== value)
      : [...selectedStatus, value];

    if (updatedValue.length > 0) {
      setSelectedStatus(updatedValue);

      delayOnChange(updatedValue);
    }
  };

  if (!showObjectiveStatus) {
    return null;
  }

  return (
    <div>
      <FlexRowSpaceBetween className="">
        <Body2 weight="bold">Objective status</Body2>
        <PlainButton onClick={onHide}>
          <FlexRow className="gap-1">
            <SecondaryMinusSVG />
            <Body2 kind="red400" weight="semibold">
              remove
            </Body2>
          </FlexRow>
        </PlainButton>
      </FlexRowSpaceBetween>

      {statusToggles.map(({label, color, value}) => (
        <FlexRow
          key={label}
          className="mt-5 cursor-pointer"
          onClick={() => handleChange(value)}>
          <Checkbox
            customBg={color}
            kind="import"
            checked={selectedStatus.includes(value)}
          />
          <Body2
            className="ml-1"
            weight={selectedStatus.includes(value) ? 'bold' : 'regular'}
            kind={selectedStatus.includes(value) ? 'textDark' : 'textBody'}>
            {capitalize(label)}
          </Body2>
        </FlexRow>
      ))}
    </div>
  );
};

export const GoalStatusAdditionalFilter = [
  {
    value: 'type',
    label: 'Objective type',
  },
  {
    value: 'progress',
    label: 'Objective progress',
  },
  {
    value: 'state',
    label: 'Objective state',
  },
];

export const GoalStateOptions = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'past_due',
    label: 'Past Due',
  },
  {
    value: 'ended',
    label: 'Ended',
  },
];

export const GoalTypeOptions = [
  {
    value: 'company',
    label: 'Company-wide',
  },
  {
    value: 'group',
    label: 'Group',
  },
  {
    value: 'individual',
    label: 'Self-development',
  },
];

export const goalProgressOptions = [
  {
    value: 25,
    label: 'Less than 50% complete',
  },
  {
    value: 50,
    label: '50% to 74% complete',
  },
  {
    value: 75,
    label: '75% to 99% complete',
  },
  {
    value: 100,
    label: '100% complete',
  },
];

interface GoalTypeProps {
  onChangeType: (value: string, groups?: string[]) => void;
  onCancel: () => void;
  groups: any[];
}
export const GoalType = ({onChangeType, onCancel, groups}: GoalTypeProps) => {
  const [selectedType, setSelectedType] = useState('');

  const [selectedGroups, setSelectedGroups] = useState<
    {value: string; label: string}[]
  >([]);

  const {
    groupStore: {groups: allGroups},
    groupTypeStore: {groupType},
  } = useStoreContext();

  const computeSelectedGroups = useMemo(() => {
    return selectedGroups.map((group) => ({
      ...group,
      ...getGroupTypeAndGroup(allGroups, groupType, group.value),
    }));
  }, [selectedGroups, groupType, allGroups]);

  return (
    <>
      <FlexRowSpaceBetween>
        <Body2 weight="bold">Type</Body2>
        <DropdownCustom
          collapseOnClick={true}
          menu={(handleClose: () => void) => (
            <div>
              <div>
                {GoalTypeOptions.map((type) => (
                  <DropdownItem
                    onClick={() => {
                      handleClose();
                      setSelectedType(type.label);
                      onChangeType(type.value);
                    }}>
                    {type.label}
                  </DropdownItem>
                ))}
              </div>
            </div>
          )}
          customComponent={(
            handleOpen: (event: any) => void,
            open: boolean,
          ) => (
            <Button
              onClick={handleOpen}
              width="sm"
              kind="secondary"
              style={{padding: '6px 12px', borderRadius: '6px'}}
              className="py-2 px-3 flex flex-row justify-between items-center capitalize">
              <Body2 kind={selectedType ? 'textDark' : 'textBody'}>
                {selectedType || 'Select value'}
              </Body2>

              {selectedType ? (
                <div
                  className="cursor-pointer "
                  onClick={() => {
                    setSelectedType('');
                    onCancel();
                  }}>
                  <CancelIcon style={{marginLeft: '14px', strokeWidth: 1.5}} />
                </div>
              ) : (
                <ChevronDownIcon
                  style={{
                    marginLeft: 10,
                    stroke: '#1E1E2F',
                    transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                />
              )}
            </Button>
          )}
          customDropdownWrapperStyles={{
            top: '130%',
            right: 0,
            width: '190px',
          }}
        />
      </FlexRowSpaceBetween>
      {selectedType.toLowerCase() === 'group' && (
        <div className="my-3">
          <CustomFilterMultiSelect
            options={groups}
            onChange={(data: {value: string; label: string}[]) => {
              onChangeType(
                selectedType,
                data.map((option) => option.value),
              );
              setSelectedGroups(data);
            }}
            placeholderTerm="group"
            type="group"
            selectedOptions={computeSelectedGroups}
          />
        </div>
      )}
    </>
  );
};
