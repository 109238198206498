import {useQuery, QueryKey, UseQueryOptions} from 'react-query';
import {onValue, ref, query, off, DataSnapshot} from 'firebase/database';
import {FIREBASE_BASE_URL, database} from '@utils/firebase-request';
import isEqual from 'lodash/isEqual';
import {useCallback, useEffect, useState, useMemo} from 'react';
import {authStore} from '@store/stores/auth-store';

// Generic fetch hook
export const useFetchHook = <TData = unknown, TError = unknown>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  options?: UseQueryOptions<TData, TError>,
) => {
  return useQuery<TData, TError>(queryKey, queryFn, options);
};

// One-time fetch hook
export const useOneTimeFetch = <TData = unknown, TError = unknown>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  options?: UseQueryOptions<TData, TError>,
) => {
  return useFetchHook<TData, TError>(queryKey, queryFn, {
    ...options,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useFirebaseFetch = (path: string, filter?: any) => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  // Create database reference
  const databaseRef = useMemo(
    () =>
      ref(
        database,
        `${FIREBASE_BASE_URL}${authStore.auth.user.workspace.id}/${path}`,
      ),
    [path],
  );

  const handleDataUpdate = useCallback((snapshot: DataSnapshot) => {
    try {
      const value = snapshot.val();
      setData((prevData: any) => {
        if (!isEqual(prevData, value)) {
          return value;
        }

        return prevData;
      });
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error('Unknown error occurred'),
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Apply filter if provided
  const filteredQuery = useMemo(
    () => (filter?.equalTo ? query(databaseRef, filter) : databaseRef),
    [databaseRef, filter],
  );

  const handleError = useCallback((error: Error) => {
    console.error('Firebase fetch error:', error);
    setError(error);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    // Attach listener
    onValue(filteredQuery, handleDataUpdate, handleError);

    // Cleanup function
    return () => {
      // Only remove listeners if this is the last one
      off(filteredQuery);
    };
  }, [filteredQuery, handleDataUpdate, handleError]);

  const refetch = useCallback(() => {
    // const cleanup = fetchData();
    // return cleanup;
  }, []);

  return {data, isLoading, error, refetch};
};
