import {Button} from '@ui/atoms/button';
import {CancelIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Headline2} from '@ui/atoms/typography';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {TextField} from '@ui/molecules/field/textfield';
import {Modal} from '@ui/molecules/modal';
import React, {useState} from 'react';
import {ModalRenameWrapper} from '../insights/styles';

interface RenameSectionProps {
  renameSectionId: string;
  allSections: any[];
  setRenameSectionModal: (value: string) => void;
}
export const RenameSection = ({
  renameSectionId,
  allSections,
  setRenameSectionModal,
}: RenameSectionProps) => {
  const [text, setText] = useState('');

  return (
    <div>
      {' '}
      <Modal open={!!renameSectionId} onClose={() => setRenameSectionModal('')}>
        <ModalRenameWrapper>
          <BackAction
            icon={<CancelIcon />}
            title="Close"
            onClick={() => setRenameSectionModal('')}
            width="md"
          />
          <VerticalSpacer size="16px" />
          <ModalCard title="">
            <div>
              <Headline2>Rename section </Headline2>

              <VerticalSpacer size="20px" />
              <TextField
                maxLength={25}
                label="Title"
                value={text}
                placeholder="Enter title (max 25 characters)"
                onChange={(event) => setText(event.target.value)}
                name="name"
              />

              <VerticalSpacer size="14px" />

              <Button
                kind="primary"
                width="full"
                onClick={() => {
                  // const section = allSections.find(
                  //   (section) => section.firebaseId === renameSectionId,
                  // );

                  // updateFirebaseData(`dashboard/${renameSectionId}`, {
                  //   ...section,
                  //   name: text,
                  // });

                  setRenameSectionModal('');
                  setText('');
                }}
                data-form-action={true}
                disabled={!text || text.length > 25}>
                Rename
              </Button>
            </div>
          </ModalCard>
        </ModalRenameWrapper>
      </Modal>
    </div>
  );
};

interface CreateDashboardProps {
  isOpen: boolean;
  handleClose: (value: string) => void;
}

export const CreateDashboardModal = ({
  isOpen,
  handleClose,
}: CreateDashboardProps) => {
  const [text, setText] = useState('');

  return (
    <div>
      {' '}
      <Modal open={isOpen} onClose={() => handleClose('')}>
        <ModalRenameWrapper>
          <BackAction
            icon={<CancelIcon />}
            title="Close"
            onClick={() => handleClose('')}
            width="md"
          />
          <VerticalSpacer size="16px" />
          <ModalCard title="">
            <div>
              <Headline2>New dashboard </Headline2>

              <VerticalSpacer size="20px" />
              <TextField
                maxLength={25}
                label="Title"
                value={text}
                placeholder="Enter title (max 25 characters)"
                onChange={(event) => setText(event.target.value)}
                name="name"
              />

              <VerticalSpacer size="14px" />

              <Button
                kind="primary"
                width="full"
                onClick={() => {
                  handleClose(text);
                  setText('');
                }}
                data-form-action={true}
                disabled={!text || text.length > 25}>
                Create
              </Button>
            </div>
          </ModalCard>
        </ModalRenameWrapper>
      </Modal>
    </div>
  );
};
