import {FC, memo, ReactNode} from 'react';
import {Insight} from '../interface';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {Body2, Headline2, Headline3} from '@ui/atoms/typography';
import dayjs from 'dayjs';
import {Button} from '@ui/atoms/button';
import {
  ArrowHeadDownIcon,
  ArrowLeftIcon,
  CancelIcon,
  ChevronDownIcon,
} from '@ui/atoms/icons';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {KpiInsight} from '../../dashboard/kpi-insight';
import {updateFirebaseData} from '@utils/firebase-handler';
import {userName} from '@utils/user-name';
import {PenEditIcon} from '@ui/atoms/icons/pen-edit';
import {TextField} from '@ui/molecules/field/textfield';
import {TickIcon} from '@ui/atoms/icons/tick';
import {useEditInsight} from './edit-insight-hook';
import {EditInsightFilter} from './edit-insight-filter';
import {ActionItemCompletionRate} from '../action-items/action-items-completion';
import {ActionItemsDistribution} from '../action-items/action-items-distribution';
import {ActionItemCompleted} from '../action-items/action-items-completed';
import {ActionItemDistributionPerIndividual} from '../action-items/action-items-distribution-individual';
import {Avatar} from '@ui/atoms/avatar';
import {VerticalInsightConfig} from '../../dashboard/insight-config';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ObjectiveCompletion} from '../objectives/objective-completion';
import {ObjectiveStatus} from '../objectives/objective-status';
import {KeyResultPerformance} from '../objectives/key-result-performance';
import {IDashboard} from '../../dashboard/dashboard-hook';
import {SimpleCustomDropdown} from '@ui/molecules/dropdown-custom/simple-dropdown-custom';

interface EditInsightProps extends Insight {
  onClose: () => void;
  firebaseId: string;
  dashboards: IDashboard[];
  handleAddToDashboard: (value: string[], dashboardId: string) => void;
  onDuplicate?: () => void;
  updateEditInsight?: (id: string) => void;
}

export const EditInsight: FC<EditInsightProps> = memo(
  ({
    name,
    updatedAt,
    onClose,
    type,
    createdBy,
    firebaseId,
    id,
    dashboards = [],
    handleAddToDashboard,
    updateEditInsight,
    onDuplicate,
    section,
    config,
    ...rest
  }) => {
    const {
      isEditingName,
      updateCustomDateRange,
      KPIOptions,
      insightConfig,
      customDateRange,
      insightName,
      setInsightName,
      isCustomDateRange,
      updateInsight,
      nameText,
      setIsEditingName,
      configIsChanged,
      handleReset,
      setNameText,
      additionalFilters,
      canAddFilter,
      findViewType,
      comparisonOptions,
      findUser,
      dateRangeOptions,
      dateRangeType,
      isIncludedInDashboard,
      handleDuplicate,
      handleSave,
    } = useEditInsight({
      ...rest,
      name,
      section,
      id,
      config,
      firebaseId,
      type,
      onClose,
      updateEditInsight,
    });

    const isKeyResults = id === 'key-results-performance';

    const isKpi = type === 'kpi';

    const isKpiChart = insightConfig.view === 'chart';

    const WidgetType = () => {
      if (type === 'kpi') {
        return (
          <div className={isKpiChart ? '' : 'max-w-[300px]'}>
            <KpiInsight
              {...rest}
              name={name}
              config={insightConfig}
              createdBy={createdBy}
              updatedAt={updatedAt}
              id={id}
              type={type}
            />
          </div>
        );
      }
      if (id === 'objective-completion') {
        return (
          <div className="max-w-[300px]">
            <ObjectiveCompletion
              {...rest}
              name={name}
              config={insightConfig}
              createdBy={createdBy}
              updatedAt={updatedAt}
              id={id}
              type={type}
            />
          </div>
        );
      }
      if (id === 'objective-status') {
        return (
          <div className="">
            <ObjectiveStatus
              {...rest}
              name={name}
              config={insightConfig}
              createdBy={createdBy}
              updatedAt={updatedAt}
              id={id}
              type={type}
            />
          </div>
        );
      }
      if (isKeyResults) {
        return (
          <div className={insightConfig.key_result ? 'max-w-[300px]' : ''}>
            <KeyResultPerformance
              {...rest}
              name={name}
              config={insightConfig}
              createdBy={createdBy}
              updatedAt={updatedAt}
              id={id}
              type={type}
            />
          </div>
        );
      }
      if (id === 'action-items-completed') {
        return (
          <div className="max-w-[300px]">
            <ActionItemCompleted
              {...rest}
              name={name}
              createdBy={createdBy}
              config={insightConfig}
              updatedAt={updatedAt}
              id={id}
              type={type}
            />
          </div>
        );
      }
      if (id === 'action-items-completion-rate') {
        return (
          <div className="max-w-[300px]">
            <ActionItemCompletionRate
              {...rest}
              name={name}
              createdBy={createdBy}
              config={insightConfig}
              updatedAt={updatedAt}
              id={id}
              type={type}
            />
          </div>
        );
      }
      if (id === 'action-items-distribution') {
        return (
          <ActionItemsDistribution
            {...rest}
            name={name}
            createdBy={createdBy}
            config={insightConfig}
            updatedAt={updatedAt}
            id={id}
            type={type}
          />
        );
      }
      if (id === 'action-items-completion-per-individual') {
        return (
          <ActionItemDistributionPerIndividual
            {...rest}
            name={name}
            createdBy={createdBy}
            config={insightConfig}
            updatedAt={updatedAt}
            id={id}
            type={type}
          />
        );
      }
      return (
        <KpiInsight
          name={name}
          createdBy={createdBy}
          {...rest}
          config={config}
          updatedAt={updatedAt}
          id={id}
          type={type}
        />
      );
    };

    return (
      <div>
        <FlexRowSpaceBetween className="border-b border-b-borderLight pb-3 pt-2 px-16">
          <button onClick={onClose}>
            <FlexRow className="gap-3">
              <ArrowLeftIcon />

              <Body2 weight="semibold">Go back</Body2>
            </FlexRow>
          </button>
          <Headline3>Saved Insights</Headline3>
          <div />
        </FlexRowSpaceBetween>
        <div className="p-16">
          <FlexRowSpaceBetween>
            <div>
              <FlexRow className="gap-3 w-full">
                {isEditingName ? (
                  <>
                    <TextField
                      placeholder="Enter title (max 25 characters)"
                      margin
                      value={nameText}
                      onChange={(event) => setNameText(event.target.value)}
                      inputStyle={{width: '28vw'}}
                    />
                    <button
                      disabled={!nameText}
                      onClick={() => {
                        setInsightName(nameText);

                        setNameText('');
                        setIsEditingName(false);
                      }}>
                      <TickIcon fill={!nameText ? '#BFBFD4' : '#585ADF'} />
                    </button>
                    <button
                      onClick={() => {
                        setNameText('');
                        setIsEditingName(false);
                      }}>
                      <CancelIcon style={{width: '24px', height: '24px'}} />
                    </button>
                  </>
                ) : (
                  <>
                    <Headline2>{insightName}</Headline2>
                    {!!createdBy && (
                      <button
                        onClick={() => {
                          setIsEditingName(true);
                          setNameText(insightName);
                        }}>
                        <PenEditIcon
                          width={'16px'}
                          height={'16px'}
                          fill="#585ADF"
                        />
                      </button>
                    )}
                  </>
                )}
              </FlexRow>

              <FlexRow className="mt-2 gap-2">
                <Body2 kind="textBody">
                  Last modified on {dayjs(updatedAt).format('DD MMM. `YY')} by
                </Body2>

                <span className="mb-1">
                  <Avatar
                    tooltip={!!createdBy}
                    userId={createdBy}
                    name={createdBy ? userName(findUser(createdBy)) : 'Beam'}
                    size="xs"
                  />
                </span>
                <Body2 kind="textBody">
                  {createdBy ? userName(findUser(createdBy)) : 'Beam'}
                </Body2>
              </FlexRow>
            </div>
            <FlexRow className="gap-4">
              <SimpleCustomDropdown
                menu={(handleOpen, open) => (
                  <Button
                    disabled={
                      insightConfig.type === 'custom' &&
                      (!customDateRange.starts || !customDateRange.ends)
                    }
                    onClick={handleOpen}
                    className="flex flex-row items-center"
                    width="sm">
                    Save
                    <ArrowHeadDownIcon
                      style={{
                        marginLeft: 14,
                        stroke: '#fff',
                        transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                      }}
                    />
                  </Button>
                )}
                options={[
                  {
                    label: 'Update existing report',
                    onClick: () => {
                      handleSave();
                    },
                  },
                  {
                    label: 'Save as new report',
                    onClick: () => {
                      handleSave(true);
                    },
                  },
                ]}
              />

              {isIncludedInDashboard ? (
                <Button
                  width="sm"
                  kind="secondary"
                  className="bg-backgroundLight"
                  onClick={() => {
                    updateFirebaseData(`insights/${firebaseId}`, {
                      name,
                      config,
                      updatedAt,
                      createdBy,
                      ...rest,
                      id,
                      type,
                      section: '',
                      dashboard: '',
                    });
                  }}>
                  Remove from dashboard
                </Button>
              ) : (
                <SimpleCustomDropdown
                  menu={(handleOpen, open) => (
                    <Button
                      disabled={
                        insightConfig.type === 'custom' &&
                        (!customDateRange.starts || !customDateRange.ends)
                      }
                      onClick={handleOpen}
                      className="flex flex-row items-center"
                      width="sm">
                      Add to dashboard
                      <ArrowHeadDownIcon
                        style={{
                          marginLeft: 14,
                          stroke: '#fff',
                          transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                        }}
                      />
                    </Button>
                  )}
                  options={dashboards?.map((dashboard) => ({
                    label: dashboard.name,
                    onClick: () =>
                      handleAddToDashboard([firebaseId], dashboard.key),
                  }))}
                />
              )}

              <SimpleCustomDropdown
                menu={(handleOpen, open) => (
                  <Button
                    onClick={handleOpen}
                    width="sm"
                    kind="secondary"
                    className="flex flex-row items-center bg-backgroundLight">
                    Actions
                    <ArrowHeadDownIcon
                      style={{
                        marginLeft: 14,
                        stroke: '#242424',
                        // transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                      }}
                    />
                  </Button>
                )}
                options={[
                  {
                    label: 'Export as .csv',
                    disabled: true,
                    onClick: () => {
                      handleSave();
                    },
                  },
                  {
                    label: 'Duplicate',
                    onClick: () => {
                      onDuplicate?.();
                      handleDuplicate();
                    },
                  },
                ]}
              />
            </FlexRow>
          </FlexRowSpaceBetween>

          <div className="gap-6 mt-6 flex flex-row">
            <div className="min-w-[70%]">
              <div className="shadow-sm border border-borderLight py-4 px-6 rounded-[10px]">
                <div className="border-b border-b-borderLight pb-4 flex items-center justify-between">
                  <VerticalInsightConfig
                    config={insightConfig}
                    disableVerticalScroll
                    additionalFilters={additionalFilters}
                  />
                  {isKpi && (
                    <DropdownCustom
                      collapseOnClick={true}
                      menu={(handleClose: () => void) => (
                        <>
                          {KPIOptions.map((option) => (
                            <DropdownItem
                              key={option.key}
                              onClick={() => {
                                handleClose();
                                updateInsight('view', option.key);
                              }}>
                              {option.title}
                            </DropdownItem>
                          ))}
                        </>
                      )}
                      customComponent={(
                        handleOpen: (event: any) => void,
                        open: boolean,
                      ) => (
                        <>
                          <div>
                            <Button
                              kind="secondary"
                              type="button"
                              onClick={handleOpen}
                              className="flex items-center justify-between"
                              style={{
                                minWidth: '125px',
                                height: '38px',
                                overflow: 'hidden',
                                padding: '6px 8px',
                              }}>
                              <FlexRowSpaceBetween>
                                <Body2 weight="bold">
                                  {findViewType(insightConfig?.view || '')}
                                </Body2>
                                <ChevronDownIcon
                                  style={{margin: '0px 5px 0px 5px'}}
                                  aria-hidden="true"
                                />
                              </FlexRowSpaceBetween>
                            </Button>
                          </div>
                        </>
                      )}
                      customDropdownWrapperStyles={{
                        top: '130%',
                        width: '200px',
                        left: '-50%',
                      }}
                    />
                  )}
                </div>
                <VerticalSpacer size="24px" />
                <WidgetType />
              </div>
            </div>

            <EditInsightFilter
              dateRangeOptions={dateRangeOptions}
              onChangeUsers={(value) => updateInsight('users', value)}
              onChangeList={(value) => updateInsight('list', value)}
              selectedLists={config.list}
              handleReset={handleReset}
              configIsChanged={configIsChanged}
              isKeyResults={isKeyResults}
              selectedUsers={config.users}
              type={type}
              insightConfig={insightConfig}
              comparisonOptions={comparisonOptions}
              canSelectObjective={id === 'objective-completion'}
              dateRangeType={dateRangeType}
              hasComparison={config.hasComparison}
              canAddFilter={canAddFilter}
              isCustomDateRange={isCustomDateRange}
              customDateRange={customDateRange}
              updateInsight={updateInsight}
              setCustomDateRange={(value, field) => {
                updateCustomDateRange(field as any, value);
              }}
            />
          </div>
        </div>
      </div>
    );
  },
);
