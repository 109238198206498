import {down, up} from 'styled-breakpoints';
import styled from 'styled-components';

export const OuterWrapper = styled.div`
  ${down('sm')} {
    padding: 20px 0;
  }

  ${up('sm')} {
    padding: 40px 0;
  }

  ${up('lg')} {
    padding: 80px 0;
  }

  ${up('xl')} {
    padding: 80px 0;
  }
`;
export const BackWrapper = styled.div`
  width: 80vw;
  margin: 0 auto;
  ${down('sm')} {
    width: 92vw;
  }

  ${up('sm')} {
    width: 95vw;
  }

  ${up('lg')} {
    width: 88vw;
  }

  ${up('xl')} {
    width: 78vw;
  }
`;

export const SuccessOuterWrapper = styled.div`
  ${down('sm')} {
    width: 92vw;
    padding: 20px 0 20px 0;
    margin-bottom: 20px;
  }

  ${up('sm')} {
    width: 95vw;
    padding: 40px 0 40px 0;
    margin-bottom: 40px;
  }

  ${up('lg')} {
    width: 50vw;
    padding: 80px;
    margin-bottom: 80px;
  }

  ${up('xl')} {
    width: 50vw;
    padding: 80px;
    margin-bottom: 80px;
  }
`;
export const SuccessWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  max-width: 623px;
  width: 80vw;
  margin: 0 auto;
  ${down('sm')} {
    width: 92vw;
  }

  ${up('sm')} {
    width: 95vw;
  }

  ${up('lg')} {
    width: 88vw;
  }

  ${up('xl')} {
    width: 78vw;
  }
`;

export const DelimitingWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  max-width: 1280px;
  width: 80vw;
  margin: 0 auto;
  ${down('sm')} {
    width: 92vw;
  }

  ${up('sm')} {
    width: 95vw;
  }

  ${up('lg')} {
    width: 88vw;
  }

  ${up('xl')} {
    width: 78vw;
  }
`;
export const ManualWrapper = styled.div`
  width: 70%;
  margin-left: 6%;
`;
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    width: 50%;

    @media (min-width: 1100px) and (max-width: 1320px) {
      width: 55%;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 80%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
export const Header = styled.div`
  margin-bottom: 2rem;
`;
export const HorizontalHr = styled.div`
  border: 1px solid #ededf2;
  opacity: 0.5;
`;

export const InvitationSent = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;

  width: 100%;
  padding: 16px;
`;

export const GroupedInvite = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px;
  .emails {
    padding: 4px 16px;
    border-radius: 6px;
    background: #f6f6f8;
    margin-right: 6px;
    margin-bottom: 4px;
  }
`;

export const ErrorInvite = styled.div`
  border-radius: 10px;
  border: 1px solid #d52a2a;
  padding: 16px;
  .emails {
    padding: 4px 16px;
    border-radius: 6px;
    background: #f6f6f8;
    margin-right: 6px;
    margin-bottom: 4px;
  }

  .error-body {
    padding: 1px;

    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(213, 42, 42, 1)' strokeWidth='2' stroke-dasharray='18%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
    .error-wrapper {
      padding: 10px 16px;
      background: #fef6f6;
    }
  }
`;
