import {Body1, Body2} from '@ui/atoms/typography';
import {FlexRow, FlexRowCenter} from '@ui/style/styles';
import {memo, useState} from 'react';
import {SecondaryPlusIcon} from '@ui/atoms/icons/plus';
import {PadIcon} from '@ui/atoms/icons/pad';
import {HorizontalSideRule, VerticalSpacer} from '@ui/atoms/spacer/spacer';
import {updateFirebaseData} from '@utils/firebase-handler';
import {PenEditIcon} from '@ui/atoms/icons/pen-edit';
import {ArrowDownSecondary} from '@ui/atoms/icons/arrow-down';
import {ArrowUpSecondary} from '@ui/atoms/icons/arrow-up';
import {TrashIcon} from '@ui/atoms/icons/trash';
import {IDashboard, useDashboardHook} from './dashboard-hook';
import {RenameSection} from './rename-section';
import {CreateSection} from './create-section';
import {ItemLoader} from '@ui/organisms/item-loader';
import {WidgetLayout} from './widget-layout';
import {EmptyDashboardState} from './empty-dashboard';
import {CancelIcon, SettingsIcon} from '@ui/atoms/icons';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {Modal} from '@ui/molecules/modal';
import {EditInsight} from '../insights/edit-insight/edit-insight';
import {ModalWrapper} from '../insights/styles';
import {DeleteSection} from './delete-section';
import {DashboardSettings} from './dashboard-settings';
import InsightsPage from '../insights';

interface ReportingDashboardProps {
  dashboardId?: string;
}
const ReportingDashboard = memo(({dashboardId}: ReportingDashboardProps) => {
  const {
    sections,
    updateOrder,
    insights,
    isLoading,
    dashboards,
  } = useDashboardHook(dashboardId);

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const [editInsight, setEditInsight] = useState('');

  const editedInsight = (id: string) => insights?.[id];

  const insight = editedInsight(editInsight);

  const [showInsight, setShowInsight] = useState(false);

  const [showNewSectionModal, setNewSectionModal] = useState(false);

  const [renameSectionId, setRenameSectionModal] = useState('');

  const [deleteSectionid, setDeleteSectionId] = useState('');

  const [isEditingLayout, setIsEditingLayout] = useState(false);

  const handleAddToDashboard = (
    selectedInsights: string[],
    dashboardId: string,
  ) => {
    selectedInsights.forEach((id) => {
      const insight = editedInsight(id);

      if (!insight?.dashboard) {
        updateFirebaseData(`insights/${insight?.firebaseId}`, {
          ...insight,
          section: 'default',
          dashboard: dashboardId,
        });
      }
    });
  };

  const handleWidgetAdd = async (widgets: any[], slug: string) => {
    widgets.forEach((widget: {firebaseId: string}, index: any) => {
      if (widget.firebaseId) {
        const firebaseId = widget.firebaseId;

        const insight = insights[firebaseId];

        if (insight && insight.section !== slug) {
          updateFirebaseData(`insights/${firebaseId}`, {
            ...insight,
            orderIndex: index,
            section: slug,
          });
        } else {
          updateFirebaseData(`insights/${firebaseId}`, {
            ...insight,
            orderIndex: index,
          });
        }
      }
    });
  };
  const isEmpty =
    !sections.length || sections.every((section) => !section.widget.length);

  return (
    <div className="mt-4">
      <div className="bg-white rounded-[10px] p-4 md:p-6 border border-borderLight h-full gap-4">
        {isEmpty && !isLoading ? (
          <EmptyDashboardState
            toggleSettings={() => setShowSettingsModal(true)}
            toggleInsight={() => setShowInsight(true)}
          />
        ) : null}

        {!isEmpty ? (
          <div className="flex flex-row flex-wrap justify-end pb-4 sm:pb-6 gap-3 mr-0 sm:mr-3">
            {isEditingLayout ? (
              <button
                onClick={() => setNewSectionModal(true)}
                className="w-full sm:w-auto">
                <FlexRow className="gap-2 justify-center sm:justify-start">
                  <SecondaryPlusIcon />
                  <Body2 weight="semibold" kind="purple300">
                    New section
                  </Body2>
                </FlexRow>
              </button>
            ) : (
              <button onClick={() => setShowInsight(true)} className="">
                <FlexRow className="gap-2 justify-center sm:justify-start">
                  <SecondaryPlusIcon />
                  <Body2 weight="semibold" kind="purple300">
                    Add Insight
                  </Body2>
                </FlexRow>
              </button>
            )}

            <HorizontalSideRule className="sm:hidden block" size="20px" />

            <button onClick={() => setIsEditingLayout(!isEditingLayout)}>
              <FlexRow className="gap-2 justify-center sm:justify-start">
                <PadIcon />
                <Body2 weight="semibold" kind="purple300">
                  {isEditingLayout ? 'Exit layout edit' : 'Edit layout'}
                </Body2>
              </FlexRow>
            </button>

            <HorizontalSideRule className="sm:hidden block" size="20px" />

            <button onClick={() => setShowSettingsModal(true)}>
              <FlexRow className="gap-2 justify-center sm:justify-start">
                <SettingsIcon />
                <Body2 weight="semibold" kind="purple300">
                  Settings
                </Body2>
              </FlexRow>
            </button>
          </div>
        ) : null}

        {!sections.length && isLoading && (
          <FlexRowCenter className="min-h-[200px]">
            <ItemLoader />
          </FlexRowCenter>
        )}

        {!isLoading &&
          !isEmpty &&
          sections.map((section) => (
            <div key={section.name}>
              <div className="shadow-[0_3px_24px_0_rgba(208,208,221,0.16)] bg-white border border-borderLight pt-4 pb-6 px-4 sm:px-6 mb-6 rounded-[10px]">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4 sm:gap-0">
                  <FlexRow className="mb-4 gap-2">
                    <Body1 weight="bold">{section.name}</Body1>
                    <button
                      className="mb-1"
                      onClick={() => setRenameSectionModal(section.firebaseId)}>
                      <PenEditIcon />
                    </button>
                  </FlexRow>

                  {isEditingLayout && (
                    <FlexRow className="gap-4 justify-end">
                      <FlexRow className="gap-1">
                        <button
                          onClick={() =>
                            updateOrder(
                              section.order + 1,
                              section.firebaseId,
                              dashboardId || '',
                            )
                          }>
                          <ArrowDownSecondary />
                        </button>
                        <button
                          onClick={() =>
                            updateOrder(
                              section.order - 1,
                              section.firebaseId,
                              dashboardId || '',
                            )
                          }>
                          <ArrowUpSecondary />
                        </button>
                      </FlexRow>
                      <button
                        disabled={!section.canDelete}
                        onClick={() => setDeleteSectionId(section.firebaseId)}>
                        <TrashIcon
                          fill={section.canDelete ? '#D52A2A' : '#BFBFD4'}
                        />
                      </button>
                    </FlexRow>
                  )}
                </div>

                <div>
                  <WidgetLayout
                    handleEditLayout={setEditInsight}
                    isEditing={isEditingLayout}
                    onAdd={(widgets) => handleWidgetAdd(widgets, section.slug)}
                    onRemove={(widget) => {}}
                    sectionId={section.firebaseId}
                    widget={section.widget as any}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
      <Modal open={!!editInsight} onClose={() => setEditInsight('')}>
        <ModalWrapper>
          <BackAction
            icon={<CancelIcon />}
            title="Close"
            onClick={() => setEditInsight('')}
            width="md"
          />
          <VerticalSpacer size="16px" />

          <ModalCard title="">
            <div>
              {insight && (
                <EditInsight
                  {...insight}
                  handleAddToDashboard={handleAddToDashboard}
                  dashboards={dashboards}
                  dashboard={dashboardId}
                  onClose={() => setEditInsight('')}
                  onDuplicate={() => {}}
                  firebaseId={insight.firebaseId || ''}
                />
              )}
            </div>
          </ModalCard>
        </ModalWrapper>
      </Modal>

      <RenameSection
        allSections={sections}
        renameSectionId={renameSectionId}
        setRenameSectionModal={setRenameSectionModal}
      />

      <CreateSection
        allSections={sections}
        setNewSectionModal={setNewSectionModal}
        showNewSectionModal={showNewSectionModal}
      />

      {showInsight && (
        <InsightModal
          onClose={() => setShowInsight(false)}
          dashboards={dashboards}
        />
      )}

      {showSettingsModal && dashboardId && (
        <DashboardSettings
          isOpen={showSettingsModal}
          handleClose={() => setShowSettingsModal(false)}
          dashboardId={dashboardId}
        />
      )}

      {deleteSectionid && (
        <DeleteSection
          onClose={() => setDeleteSectionId('')}
          // topic={data[deleteSectionid]?.name}
          topic=""
          handleDelete={() => {
            // update the section removal
            // removeFirebaseData(`dashboard/${deleteSectionid}`);
            setDeleteSectionId('');
          }}
        />
      )}
    </div>
  );
});

export default ReportingDashboard;

interface InsightModalProps {
  onClose: () => void;
  dashboards: IDashboard[];
}
const InsightModal = ({onClose, dashboards}: InsightModalProps) => {
  return (
    <Modal open={true} onClose={() => onClose()}>
      <ModalWrapper>
        <div className="flex justify-end mb-3">
          <BackAction
            icon={<CancelIcon />}
            title="Close"
            onClick={() => onClose()}
            width="md"
          />
        </div>
        <ModalCard title="" modalStyle={{padding: 0}}>
          <div>
            <InsightsPage dashboards={dashboards} />
          </div>
        </ModalCard>
      </ModalWrapper>
    </Modal>
  );
};
