import {CSSProperties, memo, SVGProps} from 'react';

export const MoreVerticalIcon = memo(({style}: {style?: CSSProperties}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
      stroke="#5E5E73"
      strokeWidth="2"
      style={style}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
      stroke="#5E5E73"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={style}
    />
    <path
      d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
      stroke="#5E5E73"
      strokeWidth="2"
      strokeLinecap="round"
      style={style}
      strokeLinejoin="round"
    />
  </svg>
));
export const MoreVerticalIcon2 = memo((props?: SVGProps<any>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.16634 8.0013C9.16634 7.35697 8.64401 6.83464 7.99967 6.83464C7.35534 6.83464 6.83301 7.35697 6.83301 8.0013C6.83301 8.64563 7.35534 9.16797 7.99967 9.16797C8.64401 9.16797 9.16634 8.64563 9.16634 8.0013Z"
      fill="#BFBFD4"
      stroke="#BFBFD4"
      {...props}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.49935 8.0013C4.49935 7.35697 3.97701 6.83464 3.33268 6.83464C2.68835 6.83464 2.16602 7.35697 2.16602 8.0013C2.16602 8.64563 2.68835 9.16797 3.33268 9.16797C3.97701 9.16797 4.49935 8.64563 4.49935 8.0013Z"
      fill="#BFBFD4"
      stroke="#BFBFD4"
      {...props}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8333 8.0013C13.8333 7.35697 13.311 6.83464 12.6667 6.83464C12.0223 6.83464 11.5 7.35697 11.5 8.0013C11.5 8.64563 12.0223 9.16797 12.6667 9.16797C13.311 9.16797 13.8333 8.64563 13.8333 8.0013Z"
      fill="#BFBFD4"
      stroke="#BFBFD4"
      {...props}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
MoreVerticalIcon.displayName = 'MoreVerticalIcon';
