import {FlexRowSpaceBetween} from '@ui/style/styles';
import {down} from 'styled-breakpoints';
import styled from 'styled-components';
import {ifProp} from 'styled-tools';

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
  .form {
    width: 60%;

    margin-bottom: 30px;
    @media (min-width: 1100px) and (max-width: 1320px) {
      width: 70%;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 88%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const SubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${down('sm')} {
    flex-direction: column;
    align-items: flex-end;
  }
`;
export const BehaviourWrapper = styled.div`
  background: #f6f6f8;
  padding: 12px;
  margin-left: 12px;
  margin-right: 15px;
  border-radius: 0px 0px 10px 10px;
  .textField {
    height: 54px;
  }
  .textFieldWrapper {
    width: 85%;
  }
  ${down('sm')} {
    height: fit-content;

    .textField {
      height: 76px;
    }
    .textFieldWrapper {
      width: 100%;
    }
  }
`;

export const QuestionReviewWrapper = styled.div`
  background: #f6f6f8;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 16px;
`;

export const QuestionContainer = styled.div`
  border-radius: 10px;
  border: 1px solid #ededf2;
  background-color: white;
  padding: 12px;
  .rule {
    background: #ededf2;
    height: 1px;
    margin: 0px 20px;
    width: 95%;
  }
`;

export const MultiOption = styled.div<{active?: boolean}>`
  border: ${ifProp('active', '1px solid #585ADF', '1px solid #EDEDF2')};
  background: ${ifProp('active', '#F6F6FE', 'white')};
  border-radius: ${ifProp('active', '10px 10px 0px 0px', '10px')};
  padding: 16px 24px;
  cursor: pointer;
  margin-left: 12px;
  margin-right: 15px;
`;

export const WrittenContainer = styled.div`
  border-radius: 10px;
  border: 1px solid var(--border-light, #ededf2);
  background: var(--others-white, #fff);
  padding: 16px 24px;
  margin-bottom: 12px;

  .tag {
    border-radius: 8px;
    background: var(--background-dark, #f6f6f8);
    padding: 4px 8px;
    margin-left: 8px;
  }
`;
export const TagName = styled.div`
  border-radius: 8px;
  background: var(--background-dark, #f6f6f8);
  padding: 8px 16px;
`;

export const AverageTitle = styled.div`
  border-radius: 8px;
  background: var(--others-white, #fff);
  padding: 6px 12px;
`;

export const WidgetWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #ededf2;
  background-color: white;
  margin-top: 15px;
  padding: 12px;
  .body-wrapper {
    border-radius: 10px;
    background: var(--background-dark, #f6f6f8);
    padding: 14px 16px;
  }
`;

export const HeaderWidget = styled(FlexRowSpaceBetween)`
  border-radius: 10px;
  border: 1px solid var(--border-light, #ededf2);
  background: var(--others-white, #fff);
  padding: 24px;
`;

export const EmptyGoal = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(243, 156, 154, 1)' strokeWidth='1' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
  height: 48px;
`;

export const GoalRating = styled.div`
  border-radius: 10px;
  border: 1px solid var(--border-light, #ededf2);
  background: var(--others-white, #fff);
  padding: 24px 16px;
`;
