import React from 'react';

export const DashCircle = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.63281 9.98047C2.34615 12.2738 4.26615 14.0405 6.65282 14.5271"
        stroke="#BFBFD4"
        strokeWidth="1.2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.36719 7.32065C1.70719 3.95398 4.54719 1.33398 8.00052 1.33398C11.4539 1.33398 14.2939 3.96065 14.6339 7.32065"
        stroke="#BFBFD4"
        strokeWidth="1.2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.34375 14.5337C11.7238 14.047 13.6371 12.3003 14.3637 10.0137"
        stroke="#BFBFD4"
        strokeWidth="1.2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
