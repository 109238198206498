import React from 'react';

export const PadIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83594 0.335938H3.5026C2.02984 0.335938 0.835938 1.52984 0.835938 3.0026V5.0026C0.835938 6.47536 2.02984 7.66927 3.5026 7.66927H4.83594C6.3087 7.66927 7.5026 6.47536 7.5026 5.0026V3.0026C7.5026 1.52984 6.3087 0.335938 4.83594 0.335938ZM3.5026 1.33594H4.83594C5.75641 1.33594 6.5026 2.08213 6.5026 3.0026V5.0026C6.5026 5.92308 5.75641 6.66927 4.83594 6.66927H3.5026C2.58213 6.66927 1.83594 5.92308 1.83594 5.0026V3.0026C1.83594 2.08213 2.58213 1.33594 3.5026 1.33594Z"
        fill="#585ADF"
      />
      <path
        d="M11.3359 1.0026C11.3359 0.726462 11.1121 0.502604 10.8359 0.502604C10.5598 0.502604 10.3359 0.726462 10.3359 1.0026V2.5026H8.83594C8.55979 2.5026 8.33594 2.72646 8.33594 3.0026C8.33594 3.27875 8.55979 3.5026 8.83594 3.5026H10.3359V5.0026C10.3359 5.27875 10.5598 5.5026 10.8359 5.5026C11.1121 5.5026 11.3359 5.27875 11.3359 5.0026V3.5026H12.8359C13.1121 3.5026 13.3359 3.27875 13.3359 3.0026C13.3359 2.72646 13.1121 2.5026 12.8359 2.5026H11.3359V1.0026Z"
        fill="#585ADF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1693 6.33594H11.5026C12.9754 6.33594 14.1693 7.52984 14.1693 9.0026V11.0026C14.1693 12.4754 12.9754 13.6693 11.5026 13.6693H10.1693C8.69651 13.6693 7.5026 12.4754 7.5026 11.0026V9.0026C7.5026 7.52984 8.69651 6.33594 10.1693 6.33594ZM10.1693 7.33594C9.2488 7.33594 8.5026 8.08213 8.5026 9.0026V11.0026C8.5026 11.9231 9.2488 12.6693 10.1693 12.6693H11.5026C12.4231 12.6693 13.1693 11.9231 13.1693 11.0026V9.0026C13.1693 8.08213 12.4231 7.33594 11.5026 7.33594H10.1693Z"
        fill="#585ADF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.7412 8.98784C4.36928 8.34732 3.41616 8.34033 3.03187 8.97041L1.13892 12.074C0.716908 12.766 1.2993 13.5026 1.9884 13.5026H5.68351C6.36474 13.5026 6.94485 12.7829 6.54336 12.0915L4.7412 8.98784ZM2.04886 12.5026L3.87854 9.5027L3.88159 9.50261L3.8838 9.50269L5.62573 12.5026H2.04886Z"
        fill="#585ADF"
      />
    </svg>
  );
};
