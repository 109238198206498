import React from 'react';

export const GoogleCalenderIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <g clipPath="url(#clip0_38520_61664)">
          <path
            d="M18.3161 5.68359H5.68457V18.3151H18.3161V5.68359Z"
            fill="white"
          />
          <path
            d="M18.3156 24.0012L23.9998 18.3169L21.1577 17.832L18.3156 18.3169L17.7969 20.9166L18.3156 24.0012Z"
            fill="#EA4335"
          />
          <path
            d="M0 18.3169V22.1064C0 23.1533 0.847875 24.0012 1.89469 24.0012H5.68425L6.26784 21.159L5.68425 18.3169L2.58741 17.832L0 18.3169Z"
            fill="#188038"
          />
          <path
            d="M23.9998 5.68425V1.89469C23.9998 0.847875 23.152 0 22.1052 0H18.3156C17.9698 1.40963 17.7969 2.44697 17.7969 3.11212C17.7969 3.77719 17.9698 4.63462 18.3156 5.68425C19.5728 6.04425 20.5201 6.22425 21.1577 6.22425C21.7953 6.22425 22.7427 6.04425 23.9998 5.68425Z"
            fill="#1967D2"
          />
          <path
            d="M23.9997 5.68359H18.3154V18.3151H23.9997V5.68359Z"
            fill="#FBBC04"
          />
          <path
            d="M18.3161 18.3164H5.68457V24.0007H18.3161V18.3164Z"
            fill="#34A853"
          />
          <path
            d="M18.3158 0H1.89478C0.847875 0 0 0.847875 0 1.89469V18.3158H5.68425V5.68425H18.3158V0Z"
            fill="#4285F4"
          />
          <path
            d="M8.27529 15.4829C7.80316 15.164 7.47626 14.6982 7.29785 14.0823L8.39369 13.6308C8.49307 14.0097 8.66679 14.3034 8.91476 14.5118C9.16104 14.7203 9.46104 14.8229 9.81157 14.8229C10.17 14.8229 10.4779 14.714 10.7352 14.496C10.9926 14.2781 11.1221 14.0002 11.1221 13.664C11.1221 13.3197 10.9863 13.0387 10.7148 12.8208C10.4432 12.6029 10.1021 12.494 9.69476 12.494H9.06157V11.4093H9.62998C9.98051 11.4093 10.2757 11.3145 10.5157 11.1249C10.7557 10.9356 10.8757 10.6765 10.8757 10.3465C10.8757 10.0529 10.7684 9.81919 10.5537 9.64397C10.339 9.46866 10.0673 9.38025 9.73732 9.38025C9.41519 9.38025 9.15945 9.46556 8.96998 9.63759C8.78061 9.81016 8.63824 10.0281 8.55626 10.2708L7.47157 9.81919C7.6152 9.41184 7.87891 9.05184 8.26573 8.74078C8.65263 8.42972 9.14679 8.27344 9.74679 8.27344C10.1905 8.27344 10.59 8.35875 10.9437 8.53078C11.2973 8.70291 11.5752 8.94131 11.7757 9.2445C11.9764 9.54919 12.0757 9.89025 12.0757 10.2692C12.0757 10.656 11.9826 10.9829 11.7964 11.2513C11.61 11.5197 11.381 11.7249 11.1094 11.8687V11.9333C11.4601 12.0779 11.7647 12.3154 11.9905 12.6203C12.2194 12.9281 12.3348 13.296 12.3348 13.7256C12.3348 14.1549 12.2257 14.5387 12.0079 14.8749C11.79 15.2113 11.4885 15.4765 11.1063 15.6692C10.7226 15.8618 10.2916 15.9598 9.81316 15.9598C9.25891 15.9613 8.74741 15.8018 8.27529 15.4829ZM15.0062 10.0449L13.8031 10.9149L13.2015 10.0024L15.3599 8.44547H16.1872V15.7892H15.0062V10.0449Z"
            fill="#4285F4"
          />
        </g>
        <defs>
          <clipPath id="clip0_38520_61664">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
