import styled, {keyframes} from 'styled-components';
import {getColorFromTheme} from '../../ui-utils';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  to {
      transform: rotate(1turn);
  }
`;

const Wrapper = styled.div`
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
`;

const LoaderWrapper = styled.div`
  position: relative;
  margin: 0;
  width: 48px;
  height: 48px;
  transition: width 80ms linear;
`;

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-45deg);
  animation: ${rotate} 1.4s cubic-bezier(0.25, 0.29, 0.54, 0.86) 0s infinite
    normal none;
`;

const TailSvg = styled(Svg)`
  animation: ${rotate} 1.4s cubic-bezier(0.41, 0.24, 0.64, 0.69) 0s infinite
    normal none;
`;

const CircleBG = styled.circle`
  fill: none;
  opacity: 0.2;
  stroke-width: 6;
  stroke: ${getColorFromTheme('borderDark')};
`;

const CirclePath = styled.circle`
  fill: none;
  stroke-width: 6;
  stroke-dasharray: 55, 200;
  stroke-dashoffset: 90;
  stroke-linecap: round;
  stroke: ${getColorFromTheme('purple300')};
`;

export const ItemLoader = () => {
  return (
    <Wrapper>
      <LoaderWrapper>
        <Svg viewBox="0 0 78 78">
          <CircleBG cx="50%" cy="50%" r="35" />
          <CirclePath cx="50%" cy="50%" r="35" />
        </Svg>
        <TailSvg viewBox="0 0 78 78">
          <CirclePath cx="50%" cy="50%" r="35" />
        </TailSvg>
      </LoaderWrapper>
    </Wrapper>
  );
};
