import {down, up} from 'styled-breakpoints';
import styled from 'styled-components';
import {getColorFromTheme} from '@ui/ui-utils';
import {FlexRowSpaceBetween} from '@ui/style/styles';
export const CalendarIconWrapper = styled.span`
  stroke: ${getColorFromTheme('purple300')};
`;
export const SubmittedHeadlineWrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 15% 15% 15%;
  padding-left: 16px;
`;
export const NotSubmittedHeadlineWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 20% 12%;
  padding-left: 16px;
`;

export const HeadlineWrapperContainer = styled.div`
  display: block;

  padding: 16px 16px 12px 0px;
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
`;
export const LinkButtonWrapper = styled.div`
  .link-button {
    width: 200px;
  }
`;
const calcColumnByGridLength = (gridLength: number) => {
  switch (gridLength) {
    case 0:
      return '100%';
    case 1:
      return '100%';
    case 2:
      return '50% 50%';
    case 3:
      return '33% 33% 33%';
    case 4:
      return '33% 33% 33% 33%';
    case 5:
      return '25% 25% 25% 25% 25%';
    default:
      return '25% 25% 25% 25% 25%';
  }
};

export const CheckinHistoryMobile = styled.div<{gridLength: number}>`
  display: none;
  @media (max-width: 500px) {
    display: block;
    margin-bottom: 16px;
    div.topl {
      border: 1px solid ${getColorFromTheme('borderLight')};
      border-radius: 10px;
      .title {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title-div {
        padding: 16px;
        border-top: 1px solid ${getColorFromTheme('borderLight')};
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .datebox {
          border: 1px solid ${getColorFromTheme('borderDark')};
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          padding: 4px 8px;
          cursor: pointer;
        }
      }
      .content-div {
        max-width: 100%;
        border-top: 1px solid #ededf2;

        overflow-x: scroll;
        .ofs {
          min-width: 588px;
          display: grid;
          grid-template-columns: ${({gridLength}) =>
            gridLength ? calcColumnByGridLength(gridLength) : '100%'};
          ${down('md')} {
            grid-template-columns: ${({gridLength}) =>
              gridLength ? calcColumnByGridLength(gridLength) : '100%'};
          }
          ${down('sm')} {
            grid-template-columns: ${({gridLength}) =>
              gridLength ? calcColumnByGridLength(gridLength) : '100%'};
          }
          padding: 0px 4px;
          .box {
            margin: 8px 4px;
            border: 1px solid ${getColorFromTheme('borderLight')};
            border-radius: 7px;
            padding: 16px;
            background-color: ${getColorFromTheme('backgroundLight')};
          }
        }
      }
    }
  }
`;

export const CheckinHistory = styled.div<{gridLength: number}>`
  margin-left: 20px;
  width: 40%;
  div.topl {
    border: 1px solid ${getColorFromTheme('borderLight')};
    border-radius: 10px;
    .title {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-div {
      padding: 16px;
      border-top: 1px solid ${getColorFromTheme('borderLight')};
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .datebox {
        border: 1px solid ${getColorFromTheme('borderDark')};
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        padding: 4px 8px;
        cursor: pointer;
      }
    }
    .content-div {
      max-width: 100%;
      border-top: 1px solid #ededf2;
      background: #fafafa;
      border-radius: 10px;
      overflow-x: scroll;
      .ofs {
        padding: 4px;
        .box {
          margin: 8px 4px;
          border: 1px solid ${getColorFromTheme('borderLight')};
          border-radius: 7px;
          padding: 16px;
          background-color: ${getColorFromTheme('backgroundLight')};
        }
      }
    }
  }
  @media (max-width: 500px) {
    display: none;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  height: fit-content;

  .summary {
    display: flex;
    justify-content: space-between;

    ${down('sm')} {
      display: block;
    }
    .box {
      border: 1px solid ${getColorFromTheme('borderLight')};
      border-radius: 10px;
      padding: 8px 8px 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 72px;
      margin: 16px 16px 16px 0px;
      flex-basis: calc(100% / 3);
      background: ${getColorFromTheme('white')};
      div.numbers {
        padding: 8px 16px;
        color: ${getColorFromTheme('textDark')};
        font-weight: 600;
        font-size: 32px;
        background: ${getColorFromTheme('borderLight')};
        border-radius: 10px;
      }
    }
  }
`;

export const SummaryWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  .table-summary {
    display: flex;
    padding: 16px 24px 24px 24px;
    justify-content: space-between;
    align-items: center;
  }
`;
export const TableContainer = styled.div`
  border-top: 1px solid #ededf2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  div::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemLoaderWrapper = styled.div`
  padding: 28px 12px 32px 22px;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ededf2;
`;

export const ItemWrapperContainer = styled.div``;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 25% 30% 18% 15% 15%;
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const HeadlineItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 21% 17% 15% 10%;
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SecondRowWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;

  min-width: 900px;
  @media (max-width: 800px) {
    max-width: 100%;
    overflow-x: auto;
    display: grid;
    min-width: 900px;
    div.header {
      min-width: 900px;
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const PaginationUl = styled.ul`
  list-style: none;
  width: 239px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 32px 0;
  padding: 4px;
  border-radius: 5px;
  border: solid 1px #cecede;
  background-color: #f6f6f8;
`;

export const PaginationLi = styled.li`
padding: 8px;
cursor: pointer;
margin: 0 1px;
color: #5f5f8c;
&:hover {
  color: #585adf;
}
  &.active { 
    width: 32px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 3px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(172, 172, 190, 0.24);
    background-color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #585adf;
    transition: background-color 0.3s ease-in;
    }
  };
`;

export const PaginationButton = styled.button`
  border: none;
  stroke: #5f5f8c;
  strokeWidth: 1.5px;
  fill: none;
  width: 100%;
  height: 100%;
  padding-top: 4px;
  &:hover {
    stroke: black;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    stroke: #5f5f8c;
  }
};
`;
export const DropdownWrapper = styled.div`
  display: block;
  position: absolute;
  min-width: 150px;
  max-width: 220px;
  width: auto;
  top: 65%;
  border-radius: 8px;
  left: 10%;
  ${down('lg')} {
    left: 10%;
  }

  ${down('md')} {
    left: 12%;
  }
  ${down('sm')} {
    left: 14%;
  }

  border: solid 1px ${getColorFromTheme('borderDark')};
  background-color: ${getColorFromTheme('white')};
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 5;
  margin-top: 16px;
`;

export const FilterContainer = styled(FlexRowSpaceBetween)`
  border-radius: 10px;
  border: 1px solid #ededf2;
  padding: 16px 24px;
`;

export const OverviewWrapper = styled.div`
  padding: 24px 16px;
  border-radius: 10px;
  border: 1px solid #ededf2;
`;

export const NavigationWrapper = styled.div`
  border-top: 1px solid #ededf2;
  display: flex;
  max-width: 100%;
  border-radius: 10px 10px 0 2px;
`;

export const Status = styled.div`
  border-radius: 8px;
  background: var(--background-dark, #f6f6f8);
  width: fit-content;
  padding: 4px 8px;
  gap: 10px;
`;

export const CommentNotif = styled.div`
  position: relative;

  .inactive {
    svg {
      path {
        fill: #bfbfd4;
      }
    }
  }
  .dot {
    position: absolute;
    bottom: 14px;
    right: -2px;
    z-index: 9999;
  }
`;

export const QuestionIconWrapper = styled.button<{tooltip?: boolean}>`
  margin-left: 8px;
  outline: none;
  position: relative;
  // z-index: 99999999;

  .tooltip {
    visibility: hidden;
    width: 399px;
    background-color: #fafafa;
    font-size: 14px;
    color: #5f5f8c;
    text-align: center;
    padding: 16px 16px 16px 16px;
    border: 1px solid #e4e5fb;
    border-radius: 5px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    // z-index: 99999999;
    left: 0;
    margin-top: -100px;
  }
  &:hover .tooltip {
    visibility: ${(props: any) => (props.tooltip ? 'visible' : '')};
    opacity: 1;
    position: absolute;
    bottom: 150%;
    left: -245%;
    max-width: 300px;
  }

  .tooltip::before {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 11.5%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: #e4e5fb transparent transparent transparent;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 11.7%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #fafafa transparent transparent transparent;
  }
`;

export const MentionIndicator = styled.div`
  background: #d52a2a;
  color: white;
  width: 16px;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  padding: 3px;
  margin-left: 6px;
  align-items: center;
  justify-content: center;
  height: 16px;
  border-radius: 20px;
`;

export const Value = styled.div<{bg: string}>`
  background: #f6f6fe;
  border: 1px solid ${({bg}) => bg};
  padding: 4px 12px;
  border-radius: 8px;
`;

export const Card = styled.div<{bg: string}>`
  .card-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
  }

  border-radius: 8px;
  border: 1px solid #ededf2;

  width: 100%;

  .compare {
    border-top: 1px solid #ededf2;
    display: flex;
    padding: 4px 12px;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

export const PulseWrapper = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 16px;
  @media (max-width: 800px) {
    display: block;
  }
`;

export const ModalWrapper = styled.div`
  max-width: 930px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80vw;
  margin: 0 auto;
  ${down('sm')} {
    width: 92vw;
  }

  ${up('sm')} {
    width: 95vw;
  }

  ${up('lg')} {
    width: 88vw;
  }

  ${up('xl')} {
    width: 78vw;
  }
`;
