import {memo} from 'react';

export const ArrowUpIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 12.667V3.333M3.332 8l4.667-4.667L12.665 8"
    />
  </svg>
));

export const ArrowUpSecondary = memo(() => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5369 1.73221C6.22786 1.47468 5.77896 1.47468 5.46992 1.73221L1.82755 4.76752C1.6775 4.89256 1.76592 5.13678 1.96124 5.13678C2.62886 5.13678 3.17008 5.678 3.17008 6.34562V11.6368C3.17008 12.097 3.54317 12.4701 4.00341 12.4701H8.00341C8.46365 12.4701 8.83674 12.097 8.83674 11.6368V6.34562C8.83674 5.678 9.37796 5.13678 10.0456 5.13678C10.2409 5.13678 10.3293 4.89256 10.1793 4.76752L6.5369 1.73221ZM4.82974 0.96399C5.50962 0.397419 6.4972 0.397421 7.17708 0.963991L10.8195 3.9993C11.688 4.7231 11.1762 6.13678 10.0456 6.13678C9.93024 6.13678 9.83674 6.23028 9.83674 6.34562V11.6368C9.83674 12.6493 9.01593 13.4701 8.00341 13.4701H4.00341C2.99089 13.4701 2.17008 12.6493 2.17008 11.6368V6.34562C2.17008 6.23028 2.07658 6.13678 1.96124 6.13678C0.830621 6.13678 0.318804 4.7231 1.18737 3.9993L4.82974 0.96399Z"
      fill="#585ADF"
    />
  </svg>
));

export const ArrowUpIconCustom = memo((color: any) => {
  const svgUp = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      stroke="#47b881"
      style={{transform: 'rotate(0deg)'}}
      // display={color === 0 ? 'none' : ''}
      viewBox="0 0 16 16">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#47b881"
        d="M8 12.667V3.333M3.332 8l4.667-4.667L12.665 8"
      />
    </svg>
  );
  const svgDown = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      stroke="#d52a2a"
      style={{transform: 'rotate(180deg)'}}
      // display={color === 0 ? 'none' : ''}
      viewBox="0 0 16 16">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#d52a2a"
        d="M8 12.667V3.333M3.332 8l4.667-4.667L12.665 8"
      />
    </svg>
  );
  return color > 0 ? svgUp : svgDown;
});

ArrowUpIcon.displayName = 'ArrowUpIcon';
