import React from 'react';

export const CandleVIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66667 1.5C4.94281 1.5 5.16667 1.72386 5.16667 2V3.33333H6C6.73638 3.33333 7.33333 3.93029 7.33333 4.66667V8.66667C7.33333 9.40305 6.73638 10 6 10H5.16667V14C5.16667 14.2761 4.94281 14.5 4.66667 14.5C4.39052 14.5 4.16667 14.2761 4.16667 14V10H3.33333C2.59695 10 2 9.40305 2 8.66667V4.66667C2 3.93029 2.59695 3.33333 3.33333 3.33333H4.16667V2C4.16667 1.72386 4.39052 1.5 4.66667 1.5ZM6 4.33333C6.18409 4.33333 6.33333 4.48257 6.33333 4.66667V8.66667C6.33333 8.85076 6.18409 9 6 9H3.33333C3.14924 9 3 8.85076 3 8.66667V4.66667C3 4.48257 3.14924 4.33333 3.33333 4.33333H6Z"
        fill="#BFBFD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1667 6H9.33333C8.59695 6 8 6.59695 8 7.33333V11.3333C8 12.0697 8.59695 12.6667 9.33333 12.6667H10.1667V14C10.1667 14.2761 10.3905 14.5 10.6667 14.5C10.9428 14.5 11.1667 14.2761 11.1667 14V12.6667H12C12.7364 12.6667 13.3333 12.0697 13.3333 11.3333V7.33333C13.3333 6.59695 12.7364 6 12 6H11.1667V2C11.1667 1.72386 10.9428 1.5 10.6667 1.5C10.3905 1.5 10.1667 1.72386 10.1667 2V6ZM9 7.33333C9 7.14924 9.14924 7 9.33333 7H12C12.1841 7 12.3333 7.14924 12.3333 7.33333V11.3333C12.3333 11.5174 12.1841 11.6667 12 11.6667H9.33333C9.14924 11.6667 9 11.5174 9 11.3333V7.33333Z"
        fill="#BFBFD4"
      />
    </svg>
  );
};
