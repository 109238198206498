import {memo} from 'react';

export const PlusIcon = memo(({styles}: {styles?: any}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    style={styles}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.657 8H2.343M8 2.343v11.314"
    />
  </svg>
));

export const SecondaryPlusIcon = memo(({stroke}: {stroke?: string}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6569 8.00012H2.34315"
      stroke={stroke || '#585ADF'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 2.34326V13.657"
      stroke={stroke || '#585ADF'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

PlusIcon.displayName = 'PlusIcon';
