import styled from 'styled-components';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {getColorFromTheme} from '../../../../../../../../ui/ui-utils';

export const Wrapper = styled.div`
  padding: 54px 64px 60px 32px;
  div.heading {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 500px) {
    padding: 54px 0px 60px 0px;
  }
`;
export const ModalDiv = styled.div`
  max-width: 500px;
  margin: auto;
  @media (max-width: 500px) {
    margin: 0px 16px;
  }
`;

export const InfoContainer = styled.div`
  background: #f6f6f8;
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  color: #5f5f8c;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const CancelButton = styled.button`
  border: 1px solid #d52a2a;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
`;
export const RetryPaymentNotificationDiv = styled.div`
  width: 80vw;
  background: ${getColorFromTheme('red400')};
  color: ${getColorFromTheme('white')};
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 94vw;
  }
`;

export const CancelReason = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(237, 237, 242, 1)' strokeWidth='2' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
  padding: 24px;
  border-radius: 12px;
  height: 150px;
  div.info {
    display: flex;
    span.price {
      font-weight: 500;
      color: ${getColorFromTheme('textDark')};
    }
  }
`;

export const PlanDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 12px;
  div.greyedOut {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    padding: 40px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${getColorFromTheme('backgroundDark')};
    @media (max-width: 500px) {
      padding: 16px;
    }
    .buttonGroup {
      display: flex;
      flex-direction: column;
    }
  }
  div.activePlanDetails {
    padding: 24px;
  }
  div.white {
    padding: 24px;
    .item {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const CardDetailsDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  div.title {
    padding: 16px 24px;
  }
  .table-body {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 2em;
      border-top: 1px solid #ededf2;
      height: 2.1em;
    }
  }
  div.header {
    padding: 16px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
    border-bottom: 1px solid ${getColorFromTheme('borderLight')};
    border-top-right: 1px solid ${getColorFromTheme('borderLight')};
    border-top-left: 1px solid ${getColorFromTheme('borderLight')};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: ${getColorFromTheme('backgroundLight')};
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 40% 35% auto;
    @media (max-width: 500px) {
      grid-template-columns: 40% 30% 15%;
    }
  }
  div.content {
    padding: 16px;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 40% 35% 20%;
    @media (max-width: 500px) {
      grid-template-columns: 40% 30% 15%;
    }
  }
`;

export const PaymentHistoryDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  div.title {
    padding: 16px 24px;
  }
  .table-body {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 2em;
      border-top: 1px solid #ededf2;
      height: 2.1em;
    }
  }
  div.header {
    padding: 16px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
    border-bottom: 1px solid ${getColorFromTheme('borderLight')};
    border-top-right: 1px solid ${getColorFromTheme('borderLight')};
    border-top-left: 1px solid ${getColorFromTheme('borderLight')};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: ${getColorFromTheme('backgroundLight')};
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 25% 25% 23% 20%;
    min-width: 510px;
    width: 100%;
    @media (max-width: 500px) {
      grid-template-columns: 25% 22% 20% 20%;
    }
  }
  div.content {
    padding: 16px;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 25% 25% 23% 20%;
    min-width: 500px;
    @media (max-width: 500px) {
      grid-template-columns: 25% 22% 20% 20%;
    }
  }
`;

export const ResponsiveFlexRowBetween = styled(FlexRowSpaceBetween)`
  .text {
    width: 60%;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    .text {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;
